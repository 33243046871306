import React from "react"
import Layout from "@layouts/layout"
import { Intro, Block, Grid, Location, Container, Banner } from "@components"
import { graphql } from "gatsby"
import ModuleArea from "../helpers/moduleArea"
import { HelmetDatoCms } from "gatsby-source-datocms"

const ShowroomList = ({ data, pageContext, location }) => {
  const {
    leadNode,
    locations,
    heading,
    content,
    seoMetaTags,
    banner,
    link
  } = data.showroomList

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: heading,
    lead: leadNode?.childMarkdownRemark?.html,
    links: [
      (link?.link?.path || link?.url) && {
        path: link?.link?.path || link?.url,
        displayText: link?.displayText
      },
      {
        path: "/learn/take-a-virtual-tour/",
        displayText: "Virtual Showroom Tour"
      }
    ]
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Block>
        <Intro {...introConfig} banner={banner} />
      </Block>
      {locations && (
        <Block>
          <Grid moduleStyle="1-up-grid">
            {locations.map(item => {
              return (
                <Location
                  listing
                  name={item.businessName}
                  addressLine1={item.addressLine1}
                  addressLine2={item.addressLine2}
                  city={item.city}
                  state={item.state}
                  salesPhoneNumber={item.salesPhoneNumber}
                  partsPhoneNumber={item.partsPhoneNumber}
                  postcode={item.postcode}
                  openHours={item.openHours}
                  email={item.email}
                  openHoursText={item.openHoursText}
                  hero={item.hero}
                  title={item.title}
                  path={item.path}
                  key={`location-list-item-${item.businessName}`}
                  isJacuzzi={item.isJacuzzi}
                  partsAndAccessoriesText={item.partsAndAccessoriesText}
                  partsAndAccessoriesUrl={item.partsAndAccessoriesUrl}
                />
              )
            })}
          </Grid>
        </Block>
      )}
      {content && (
        <Block padding="double">
          <ModuleArea modules={content} location={location} />
        </Block>
      )}
    </Layout>
  )
}

export default ShowroomList

export const query = graphql`
  query showroomListById($id: String!, $language: String!) {
    showroomList: datoCmsShowroomList(locale: $language, id: { eq: $id }) {
      heading
      id
      slug
      path
      leadNode {
        childMarkdownRemark {
          html
        }
      }
      banner {
        id
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "175"
              w: "420"
              fit: "cover"
              q: 60
              auto: "format"
            }
          )
          alt
        }
      }
      link {
        ...Link
      }
      locations {
        ...Showroom
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...ShowroomListContentModuleArea
    }
  }
`
