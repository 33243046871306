import { graphql } from "gatsby"
import uniqid from "uniqid"
import { filterByLocale } from "@helpers/miscHelpers"

const menuTreeFromPages = (nodes, content, megaMenus) => {
  const allMenuItems = nodes
    .filter(node => node.pageContext !== null && node.pageContext.showPage)
    .map(node => ({ path: node.path, ...node.pageContext }))

  const localisedMegaMenus = filterByLocale(megaMenus)

  const datoLandingPages = localisedMegaMenus
    .sort((a, b) => a.position - b.position)
    .map(megaMenu => {
      const {
        id,
        locales,
        category,
        position,
        title,
        columns,
        setToEnd
      } = megaMenu
      return {
        hasParent: false,
        id,
        language: locales,
        path: category.path,
        position: position,
        showPage: true,
        slug: category.slug,
        title,
        columns,
        treeChildren: [],
        treeParent: null,
        isMegaMenu: columns?.length > 0,
        setToEnd: setToEnd
      }
    })

  const datoPages = allMenuItems
    .filter(
      item =>
        item.hasParent === false &&
        item.type === "DatoCmsPage" &&
        item.path !== "/learn/"
    )
    .sort((a, b) => a.position - b.position)

  datoPages.forEach(parent => {
    const { treeChildren, path } = parent

    if (treeChildren.length > 0 && path === "/contact-us/") {
      parent.title = "Contact"
      parent.treeChildren = treeChildren
        .filter(child => child.showPage)
        .map(child => ({
          ...child,
          path: `${path}${child.slug}/`
        }))
        .sort((a, b) => a.position - b.position)

      process.env.GATSBY_LANGUAGE === "en-AU" &&
        parent.treeChildren.unshift({
          id: uniqid(),
          path: "/services/",
          showPage: true,
          slug: "services",
          title: "Services",
          language: process.env.GATSBY_LANGUAGE,
          position: 3
        })

      parent.treeChildren.unshift(
        {
          id: uniqid(),
          path: "/contact-us/",
          showPage: true,
          slug: "contact-us",
          title: "Contact Us",
          language: process.env.GATSBY_LANGUAGE,
          position: 0
        },
        {
          id: uniqid(),
          path: "/spa-parts/",
          showPage: true,
          slug: "spa-parts",
          title: "Parts & Chemicals",
          language: process.env.GATSBY_LANGUAGE,
          position: 1,
          isExternal: true
        },
        {
          id: uniqid(),
          path: content.customerSupport,
          showPage: true,
          title: "Customer Support",
          language: process.env.GATSBY_LANGUAGE,
          position: 2
        }
      )
    } else if (treeChildren.length > 0) {
      parent.treeChildren = treeChildren
        .filter(child => child.showPage)
        .map(child => ({
          ...child,
          path: `${path}${child.slug}/`
        }))
        .sort((a, b) => a.position - b.position)
    }
  })

  const customMenuItems = allMenuItems
    .filter(
      item => item.path === "/specials/" || item.path === "/find-a-showroom/"
    )
    .sort((a, b) => a.position - b.position)
    .map(item => {
      return {
        ...item,
        title: item.title === "Find a Showroom" ? "Showrooms" : item.title
      }
    })

  const spaPools = allMenuItems
    .filter(item => item.path === "/products/spa-pools/")
    // .sort((a, b) => a.position - b.position)
    .map(item => {
      return {
        ...item,
        title: "Spa Pools"
      }
    })

  const swimSpas = allMenuItems
    .filter(item => item.path === "/products/swim-spas/")
    // .sort((a, b) => a.position - b.position)
    .map(item => {
      return {
        ...item,
        title: "Swim Spas"
      }
    })

  customMenuItems.forEach(parent => {
    const { treeChildren, path } = parent

    if (treeChildren.length > 0) {
      parent.treeChildren = treeChildren
        .filter(child => child.showPage)
        .map(child => ({
          ...child,
          path: child.slug === "specials" ? path : `${path}${child.slug}/`
        }))
        .sort((a, b) => a.position - b.position)
    }
  })

  const endLandingPages = []
  datoLandingPages.forEach(page => {
    if (page.setToEnd) {
      endLandingPages.push(page)
    }
  })

  if (
    !endLandingPages[0]?.columns[0].collections.find(
      link => link.title === "FAQs"
    )
  ) {
    endLandingPages[0]?.columns[0].collections.push({
      id: uniqid(),
      path: content.faq,
      showPage: true,
      title: "FAQs",
      position: 3
    })
  }

  return [
    ...spaPools,
    ...swimSpas,
    ...datoLandingPages.filter(p => !p.setToEnd),
    ...customMenuItems,
    ...endLandingPages,
    ...datoPages
  ]
}

export { menuTreeFromPages }

export const query = graphql`
  fragment MenuHelpersAllPages on SitePage {
    path
    pageContext
  }
`
