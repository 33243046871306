import React, { useState } from "react"
import style from "./gallery.mod.scss"
import { FilteredList, Modal, Carousel } from "@components"
import { graphql } from "gatsby"
import { removeDuplicates, configTagsForSelect } from "@helpers/miscHelpers"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import { filterByLocale } from "@helpers/miscHelpers"

const Gallery = ({ items = [], showFilters, location }) => {
  const localisedItems = filterByLocale(items)
  const [isModalOpen, setModalOpen] = useState(false)
  const [filteredItems, setFilteredItems] = useState(localisedItems)
  const [curIndex, setCurIndex] = useState(0)

  const filterItemsForCarousel = items => {
    if (JSON.stringify(filteredItems) !== JSON.stringify(items)) {
      setFilteredItems(items)
    }
  }

  const setModal = boolean => setModalOpen(boolean)

  const transformTagToObject = tag => {
    if (!tag?.name)
      return {
        name: tag?.title || tag,
        slug: !tag?.slug ? tag.toLowerCase().replace(/ /gi, "-") : tag?.slug
      }

    return tag
  }

  const galleryProducts =
    localisedItems.length > 0 &&
    localisedItems.map(item => item?.product).filter(Boolean)

  const getTags = desiredTag => {
    if (galleryProducts && galleryProducts.length > 0) {
      return galleryProducts
        .map(product => {
          if (desiredTag !== "brand") {
            return {
              ...transformTagToObject(product[desiredTag]),
              brand: product?.brand
            }
          }

          return transformTagToObject(product[desiredTag])
        })
        .filter(Boolean)
    }

    return []
  }

  const orderTags = arr => {
    const orderedArr = arr.sort((a, b) => a.name.localeCompare(b.name))

    return removeDuplicates(orderedArr)
  }

  const configureTags = arr => {
    if (arr && arr.length > 0) {
      return orderTags(arr)
    }

    return []
  }

  const galleryBrands = configureTags([...getTags("brand")])
  const galleryModels = configureTags([...getTags("productModel")])
  const galleryCategories = configureTags([...getTags("category")])

  const filters = []
  galleryBrands.length > 1 &&
    filters.push({
      name: "brand",
      label: "brand",
      placeholder: {
        text: "Select brand"
      },
      options: [...configTagsForSelect(galleryBrands)]
    })

  galleryCategories.length > 1 &&
    filters.push({
      name: "category",
      label: "category",
      placeholder: {
        text: "Select category"
      },
      options: [...configTagsForSelect(galleryCategories)]
    })

  galleryModels.length > 1 &&
    filters.push({
      name: "model",
      label: "model",
      placeholder: {
        text: "Select model"
      },
      options: [...configTagsForSelect(galleryModels, [...galleryBrands])]
    })

  const getIndex = (id, items) => {
    const theIndex = items.findIndex(item => item.id === id)
    setCurIndex(theIndex)
  }

  const galleryItems = []
  if (localisedItems.length > 0)
    localisedItems.map(item =>
      galleryItems.push({
        ...item,
        brand: item?.product?.brand,
        model: item?.product?.productModel && {
          name: item?.product?.productModel,
          slug: item?.product?.productModel.toLowerCase().replace(/ /gi, "-")
        },
        category: item?.product?.category && {
          name: item?.product?.category?.title,
          slug: item?.product?.category?.slug
        },
        onClick: (id, items) => {
          getIndex(id, items)
          setModalOpen(true)
        },
        elevated: true,
        hover: true
      })
    )

  const slideItems = filteredItems.map(item => ({
    id: item.id,
    image: item.image,
    video: item.video,
    content:
      item?.contentNode?.childMarkdownRemark?.html ||
      (item?.product?.name && `<h3>${item?.product?.name}</h3>`),
    link: (item?.product || item?.link?.link?.path || item?.link?.url) && {
      to: item?.link?.link?.path || item?.link?.url || item?.product?.path,
      children: (
        <>
          {item?.link?.displayText || "View Product Details"}
          <FaAngleRight />
        </>
      ),
      unstyled: true
    }
  }))

  return (
    <div className={style.gallery}>
      <FilteredList
        items={galleryItems}
        filters={filters}
        type="gallery"
        onChange={filterItemsForCarousel}
        defaultQueries={new Map()}
        showFilters={showFilters}
        location={location}
      />
      <Modal onChange={setModal} globalState={isModalOpen} background={"none"}>
        <Carousel
          items={slideItems}
          moduleStyle="gallery"
          pagination={false}
          initialSlide={curIndex}
        />
      </Modal>
    </div>
  )
}

export default Gallery

export const query = graphql`
  fragment Gallery on DatoCmsGallery {
    id
    padding
    showFilters
    model {
      apiKey
    }
    linkHash
    __typename
    galleryItems {
      ...GalleryItem
    }
  }
`
