import React from "react"
import { Button } from "@components"

const ConditionalLink = ({ condition, children, config }) => {
  const wrapWithLink = () => <Button {...config} children={children} unstyled />
  return condition ? wrapWithLink(children, config) : children || null
}

const ConditionalDiv = ({ condition, children, config }) => {
  const wrapWithDiv = () => <div {...config} children={children} />
  return condition ? wrapWithDiv(children, config) : children
}

const removeDuplicates = array =>
  Array.from(new Set(array.map(a => a.name))).map(name => {
    return array.find(a => a.name === name)
  })

const configTagsForSelect = (items, optionGroups = []) => {
  const options = items.map(({ slug, name, brand = null }) => ({
    value: slug,
    text: name,
    brand: brand
  }))

  if (optionGroups.length > 1) {
    return optionGroups.map(group => {
      return {
        label: group?.name,
        options: options.filter(option => option?.brand?.slug === group?.slug)
      }
    })
  }

  return options
}

const filterByLocale = arr => {
  return arr.filter(item => {
    if (!item.locales) {
      return
    } else {
      // Check if item.locales includes the desired language
      return item.locales.includes(process.env.GATSBY_LANGUAGE)
    }
  })
}

const filterByCountry = arr =>
  arr.filter(
    item =>
      item?.country === process.env.GATSBY_LANGUAGE || item?.country === "Both"
  )

const changeTimeZone = (date, timeZone) => {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone
      })
    )
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone
    })
  )
}

const offsetDate = (initialDate, locale = "en-NZ") => {
  // Dato timezone is set to NZ by default so if the site locale is AU then the date/time must be offset
  if (locale === "en-AU") {
    return changeTimeZone(initialDate, "Australia/Sydney")
  }

  return initialDate
}

const promoChecker = (startDate, endDate, locale) => {
  const current = new Date(Date.now())
  const start = new Date(startDate)
  const end = new Date(endDate)

  const startDateTime = offsetDate(start, locale)
  const endDateTime = offsetDate(end, locale)

  return current > startDateTime && current < endDateTime
}

const getHeight = content => {
  var contentHeight = document.querySelector(`${content}`).offsetHeight

  return contentHeight
}

const transformStoreItemToCard = hit => {
  const {
    AvailableSellQuantity,
    DateArrival,
    EditableKitBundle,
    alt_image_url,
    alt_thumbnail_url,
    brand,
    categories,
    content_id,
    content_url,
    date_added,
    filter_group,
    has_parent,
    image_url,
    is_active,
    is_approved,
    is_obsolete,
    item_specifics,
    item_url,
    logged_in_user_only_purchase,
    loggedin_only,
    misc04,
    misc14,
    name,
    objectID,
    parent_sku,
    partsfinder,
    price_groups,
    product_list,
    promotion_expiry_date,
    promotion_price,
    promotion_start_date,
    ranking,
    rrp,
    search_keywords,
    shipping_category,
    special_order,
    stock_level,
    thumbnail_url,
    track_inventory,
    upc,
    user_group,
    variations,
    __position,
    _highlightResult
  } = hit

  const currentPrice = parseFloat(price_groups[1]).toFixed(2)
  const standardPrice = parseFloat(rrp).toFixed(2)
  const onSale = rrp && rrp !== 0 && currentPrice < standardPrice
  const saveValue = standardPrice - currentPrice
  const buttonText = loggedin_only === "y" ? "More Information" : "Add to Cart"

  return {
    hover: true,
    elevated: true,
    padImage: true,
    viewProduct: true,
    id: objectID,
    heading: name,
    // brand: brand,
    // price,
    // size,
    // usage,
    pricing: {
      price: promotion_price || rrp,
      isWasNow: false,
      isFromSave: true,
      onSale: onSale,
      standard: standardPrice, // why pay
      was: rrp,
      now: currentPrice, // now "xxx.xx" & From "xxx.xx"
      save: saveValue.toFixed(2), // save
      currency: "AUD",
      saveUpTo: promotion_price && rrp - promotion_price,
      storeItem: true
    },
    // finance: [
    //   {
    //     id: "DatoCmsFinance-5070314-en-AU",
    //     logo: { gatsbyImageData: {}, alt: "Zip Interest Free" },
    //     logoWide: { gatsbyImageData: {}, alt: "Zip Interest Free" },
    //     name: "Zip Interest Free Finance*",
    //     repaymentOptions: null,
    //     slug: "zip",
    //     wideIcon: true
    //   }
    // ],
    link: {
      path: item_url,
      title: buttonText
    },
    image: image_url,
    onSale: onSale
  }
}

export {
  getHeight,
  ConditionalLink,
  removeDuplicates,
  filterByLocale,
  filterByCountry,
  promoChecker,
  configTagsForSelect,
  ConditionalDiv,
  transformStoreItemToCard
}
