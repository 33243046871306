import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./product-card.mod.scss"
import { Recommendation, Price, Button, Superscript } from "@components"
import LogoSvg from "@svgs/logo.svg"
import {
  ConditionalLink,
  filterByLocale,
  promoChecker
} from "@helpers/miscHelpers"
import SaleSvg from "@svgs/sale.svg"
import SoldSvg from "@svgs/sold.svg"
import Badge3dSvg from "@svgs/badge-3d.svg"
import Clearlift from "@svgs/clearlift-small.svg"
import FreeDelivery from "@svgs/free-delivery.svg"
import FastShipping from "@svgs/fast-shipping.svg"
import MotorisedLouvres from "@svgs/motorised-louvres.svg"
import Infrared from "@svgs/infrared.svg"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"

const ProductCard = ({
  id,
  heading,
  image,
  brand,
  description,
  pricing,
  finance,
  netoVariants = [],
  dimensions = [],
  onClick,
  links = [],
  link,
  modifiers = [],
  elevated = false,
  padImage,
  hover,
  viewer,
  hoverImage,
  onSale = false,
  imageDisclaimer = false,
  extended = false,
  isAvailable = true,
  special = false,
  details = [],
  showRecommendation = true,
  isSearchResult = false,
  clearliftCompatible = false,
  infrared = false,
  freeDelivery = false,
  motorised = false
}) => {
  if (link) links.push(link)

  console.log(infrared)

  extended && modifiers.push(style["product-card--extended"])
  elevated && modifiers.push(style["product-card--elevated"])
  hover && modifiers.push(style["product-card--hover"])
  hoverImage && modifiers.push(style["product-card--has-hover-image"])
  padImage && modifiers.push(style["product-card--pad-image"])
  special && modifiers.push(style["product-card--special"])

  const data = useStaticQuery(graphql`
    {
      promotions: allDatoCmsPromotion(sort: { order: ASC, fields: position }) {
        nodes {
          startDate
          endDate
          name
          id
          badge {
            url
          }
        }
      }
    }
  `)

  const productCardConfig = {
    className: [style["product-card"], ...modifiers].join(" ")
  }
  const promos = filterByLocale(data.promotions.nodes)
  const activePromos = promos.filter(promo =>
    promoChecker(promo.startDate, promo.endDate, promo.locales)
  )

  const isPromotion = activePromos.length > 0
  const hasLinks = links.length > 0

  const primaryLinkConfig = hasLinks
    ? {
        onClick,
        ...links[0],
        to: links[0]?.to || links[0]?.path
      }
    : {}

  return (
    <div {...productCardConfig}>
      {image && (
        <ConditionalLink condition={hasLinks} config={primaryLinkConfig}>
          <div className={style["product-card__images-wrap"]}>
            <div className={style["product-card__images"]}>
              {hoverImage && (
                <GatsbyImage
                  image={hoverImage.gatsbyImageData}
                  className={style["product-card__image--hover"]}
                  loading="eager"
                />
              )}
              {image.gatsbyImageData ? (
                <GatsbyImage
                  image={image.gatsbyImageData}
                  className={style["product-card__image"]}
                />
              ) : (
                <img
                  src={image}
                  alt={image.alt || ""}
                  className={style["product-card__image-src"]}
                />
              )}
              {showRecommendation && <Recommendation productId={id} />}
              {onSale && (
                <div className={style["product-card__sale"]}>
                  {isPromotion ? (
                    <img
                      src={activePromos[0].badge.url}
                      alt={activePromos[0].name}
                    />
                  ) : (
                    <SaleSvg />
                  )}
                </div>
              )}
              {!isAvailable && (
                <div className={style["product-card__sold"]}>
                  <SoldSvg />
                </div>
              )}
              {(clearliftCompatible ||
                freeDelivery ||
                motorised ||
                infrared) && (
                <div className={style["product-card__icons"]}>
                  {clearliftCompatible && <Clearlift />}
                  {freeDelivery && <FreeDelivery />}
                  {motorised && <MotorisedLouvres />}
                  {infrared && <Infrared />}
                </div>
              )}
              {isSearchResult && pricing?.storeItem && (
                <div className={style["product-card__shipping"]}>
                  <FastShipping />
                </div>
              )}
            </div>
          </div>
          {imageDisclaimer && (
            <center className={style["product-card__disclaimer"]}>
              Actual product not shown
            </center>
          )}
        </ConditionalLink>
      )}

      <div className={style["product-card__content"]}>
        <div className={style["product-card__header"]}>
          <div className={style["product-card__heading-wrap"]}>
            <ConditionalLink condition={hasLinks} config={primaryLinkConfig}>
              {heading && (
                <h4
                  className={style["product-card__heading"]}
                  children={<Superscript children={heading} />}
                />
              )}
            </ConditionalLink>
            {pricing && (
              <div className={style["product-card__pricing"]}>
                <Price
                  prices={pricing}
                  isAggregate={netoVariants && netoVariants.length > 0}
                  modifier={extended ? "split" : "stacked"}
                  isSearchResult={isSearchResult}
                />
              </div>
            )}
          </div>
          {brand &&
            (brand.logo ? (
              <span className={style["product-card__brand"]}>
                <img alt={brand.name} src={brand.logo.url} />
              </span>
            ) : (
              <span className={style["product-card__brand"]}>
                <LogoSvg />
              </span>
            ))}
        </div>
        {dimensions && dimensions.length > 0 && (
          <div className={style["product-card__dimensions"]}>
            <dl>
              {dimensions.map(({ icon, label, name }) => (
                <Fragment key={`dimension-${name}-${label}`}>
                  <dt>{name}</dt>
                  <dd>
                    <span className={style["product-card__dimension-icon"]}>
                      {icon.url ? (
                        <img src={icon.url} alt={icon.alt} width={26} />
                      ) : (
                        icon
                      )}
                    </span>
                    <span className={style["product-card__dimension-label"]}>
                      {label}
                    </span>
                  </dd>
                </Fragment>
              ))}
            </dl>
          </div>
        )}
        {details && details.length > 0 && (
          <div className={style["product-card__details"]}>
            <dl>
              {details.map(({ name, value }, i) => (
                <div
                  key={`product-card-detail-${i}`}
                  className={style["product-card__detail"]}
                >
                  <dt>{name}</dt>
                  <dd>{value}</dd>
                </div>
              ))}
            </dl>
          </div>
        )}
        {description && (
          <p className={style["product-card__desc"]}>{description}</p>
        )}
      </div>
      <div className={style["product-card__buttons"]}>
        {links.length > 1 && (
          <div className={style["product-card__link"]} onClick={onClick}>
            <Button to={links[1].to} state={links[1].state} unstyled tertiary>
              {links[1].title}
              <FaAngleRight />
            </Button>
          </div>
        )}
      </div>

      {isSearchResult && pricing?.storeItem && (
        <div
          className={
            finance?.length > 0
              ? style["product-card__view-product"]
              : style["product-card__view-product-last"]
          }
        >
          <Button to={link.path} title={link.title} fullWidth noTarget>
            {link.title}
          </Button>
        </div>
      )}
      {finance?.length > 0 && (
        <div className={style["product-card__finance"]}>
          {finance.length > 1 ? (
            <div className={style["product-card__finance_providers"]}>
              {finance[0].logo && (
                <GatsbyImage
                  image={
                    finance[0].wideIcon
                      ? finance[0].logoWide.gatsbyImageData
                      : finance[0].logo.gatsbyImageData
                  }
                />
              )}
              <h5>{"Finance Options Available*"}</h5>
              {finance[1].logo && (
                <GatsbyImage
                  image={
                    finance[1].wideIcon
                      ? finance[1].logoWide.gatsbyImageData
                      : finance[1].logo.gatsbyImageData
                  }
                />
              )}
            </div>
          ) : (
            <>
              <div className={style["product-card__finance_provider"]}>
                {finance[0].logo && (
                  <GatsbyImage
                    image={
                      finance[0].wideIcon
                        ? finance[0].logoWide.gatsbyImageData
                        : finance[0].logo.gatsbyImageData
                    }
                  />
                )}
                <h5>{finance[0].name}</h5>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ProductCard

export const query = graphql`
  fragment ProductCard on DatoCmsProduct {
    name
    id
    slug
    path
    freeDelivery
    motorised
    clearliftCompatible
    infrared
    viewer
    heroImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {
          h: "330"
          fit: "fill"
          fill: "solid"
          w: "500"
          q: 60
          auto: "format"
        }
      )
      alt
    }
    specsImage: specificationsImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: {
          h: "330"
          fit: "fill"
          fill: "solid"
          fillColor: "f1f1f1"
          w: "500"
          q: 60
          auto: "format"
        }
      )
      alt
    }
    netoProduct {
      sku
      name
      seats
      retailPrice
      rrp
      saveUpTo
      currency
      promotionStartDate
      promotionPrice
      promotionExpiryDate
      ...ProductAggregateSpecs
      finance {
        id
        slug
        name
        repaymentOptions
        logo {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: {
              h: "22"
              fit: "fill"
              fill: "solid"
              fillColor: "00ffffff"
              w: "22"
              q: 60
              dpr: 2
            }
          )
          alt
        }
        wideIcon
        logoWide: logo {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: {
              h: "22"
              fit: "fill"
              fill: "solid"
              fillColor: "00ffffff"
              w: "44"
              q: 60
              dpr: 2
            }
          )
          alt
        }
      }
    }
    netoVariants {
      id
    }
    brand {
      name
      slug
      id
      logo {
        alt
        url
      }
      model {
        apiKey
      }
      __typename
    }
    price {
      name
      slug
      id
      model {
        apiKey
      }
      __typename
    }
    usage {
      name
      slug
      id
      model {
        apiKey
      }
      __typename
    }
    size {
      name
      slug
      id
      model {
        apiKey
      }
      __typename
    }
  }

  fragment SpecialProductCard on NetoSpecial {
    name
    brand
    description
    condition
    clearanceNote
    clearanceUrl
    categories
    dimensions
    id
    serial: sku
    seats
    saveUpTo
    rrp
    retailPrice
    promotionStartDate
    promotionPrice
    promotionExpiryDate
    specifics {
      name
      value
    }
    warehouseData {
      WarehouseState
      WarehousePostcode
    }
    datoProduct: datoSpecialProduct {
      name
      id
      slug
      country
      serial: sku
      featuredSpecs {
        value
        model {
          apiKey
        }
        __typename
        label
        id
        icon {
          alt
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "26"
              fit: "crop"
              w: "26"
              q: 60
              auto: "format"
            }
          )
        }
      }
      specs {
        value
        model {
          apiKey
        }
        __typename
        label
        id
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "330"
            fit: "fill"
            fill: "solid"
            w: "500"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      brand {
        name
        slug
        id
        logo {
          alt
          url
        }
      }
    }
  }

  fragment ClearanceProductCard on NetoSpecial {
    name
    brand
    description
    condition
    categories
    dimensions
    clearanceNote
    clearanceUrl
    id
    serial: sku
    seats
    saveUpTo
    rrp
    retailPrice
    promotionStartDate
    promotionPrice
    promotionExpiryDate
    specifics {
      name
      value
    }
    warehouseData {
      WarehouseState
      WarehousePostcode
    }
    datoProduct: datoClearanceProduct {
      name
      id
      slug
      country
      serial: sku
      featuredSpecs {
        value
        model {
          apiKey
        }
        __typename
        label
        id
        icon {
          alt
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fit: "crop", w: "26", q: 60, auto: "format" }
          )
          alt
        }
      }
      specs {
        value
        model {
          apiKey
        }
        __typename
        label
        id
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "330"
            w: "500"
            fit: "fill"
            fill: "solid"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      brand {
        name
        slug
        id
        logo {
          alt
          url
        }
      }
    }
  }
`
