import React from "react"
import { graphql } from "gatsby"
import style from "./blurb-grid.mod.scss"
import { Card, Container } from "../components"

const BlurbGrid = ({ items, layout }) => {
  const classes = [style.blurbs]

  layout === "3-wide" && classes.push(`${style.blurbs}--3-wide`)
  return (
    <Container>
      <div className={classes.join(" ")}>
        {items.map(item => (
          <Card elevated image={item.image} content={item.content} />
        ))}
      </div>
    </Container>
  )
}

export default BlurbGrid

export const query = graphql`
  fragment BlurbGrid on DatoCmsBlurbGrid {
    id
    __typename
    padding
    items {
      id
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "620", fit: "crop", w: "1140", q: 60 }
        )
        alt
      }
      content
    }
    layout
    model {
      apiKey
    }
  }
`
