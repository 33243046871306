import React from "react"
import { graphql } from "gatsby"
import { Block } from "@components"

const Feed = ({ data }) => {
  const head = [
    "id",
    "mpn",
    "title",
    "product_type",
    "brand",
    "image_link",
    "description",
    "pubDate",
    "price",
    "condition",
    "availability",
    "link",
    "sale_​​price",
    "sale_​​price_​​effective_​​date"
  ]
  const { products } = data.allDatoCmsProduct
  const { websiteUrl } = data.site.siteMetadata

  const body = products.map(
    ({
      id,
      title,
      heroImage: {
        fixed: { image }
      },
      excerpt,
      meta: { pubDate },
      netoProduct,
      brand,
      usage,
      link
    }) => {
      if (!netoProduct) {
        console.error(
          `Product ${title} is missing neto product pricing data. Check sku in DatoCMS`
        )
        return null
      }

      if (!websiteUrl) {
        console.error(`Shopping feed missing host name. Contact admin`)
        return null
      }

      const {
        currency,
        retailPrice,
        promotionPrice,
        promotionExpiryDate,
        promotionStartDate
      } = netoProduct

      const host = websiteUrl.replace(/\/$/, "")

      return [
        id, // ID
        id.replace(/^Z/, ""), // MPN
        title, // Title
        usage[0] ? usage[0].name : "", // Type
        brand ? brand.name : "", // Brand
        image, // Image
        excerpt, // Description
        pubDate, // pubDate,
        `${retailPrice} ${currency}`, // Price
        "new", // condition
        "in stock", // availability
        `${host}${link}`,
        `${promotionPrice} ${currency}`, // Sale Price
        `${promotionStartDate.replace(
          " ",
          "T"
        )}+1200 / ${promotionExpiryDate.replace(" ", "T")}+1200` // Sale Dates
      ]
    }
  )

  return (
    <Block gutters padding={"both"}>
      <div style={{ width: "100%", overflow: "scroll", padding: "2rem" }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              {head.map((cell, i) => (
                <th key={`head-${i}`}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((row, i) => (
              <tr key={`body-row-${i}`}>
                {row.map((cell, ii) => (
                  <td key={`body-row-${i}-cell-${ii}`} valign="top">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Block>
  )
}

export const query = graphql`
  query productFeed($language: String!) {
    site {
      siteMetadata {
        websiteUrl
      }
    }

    allDatoCmsProduct(
      filter: {
        showAsProductPage: { eq: true }
        country: { in: [$language, "Both"] }
        locales: { eq: $language }
      }
    ) {
      products: nodes {
        title: name
        excerpt
        meta {
          pubDate: publishedAt(formatString: "dddd MMMM D YYYY")
        }
        heroImage {
          fixed(width: 600) {
            image: src
          }
        }
        netoProduct {
          promotionPrice
          promotionExpiryDate
          promotionStartDate
          retailPrice
          rrp
          saveUpTo
          currency
        }
        link: path
        slug
        id: sku
        usage {
          name
        }
        brand {
          name
        }
      }
    }
  }
`

export default Feed
