import React from "react"
import Layout from "@layouts/layout"
import { graphql } from "gatsby"
import { Block, Cards, Card, Container, NotFoundPageIntro } from "@components"
import { HelmetDatoCms } from "gatsby-source-datocms"

const NotFoundPage = () =>
  //   {
  //   data: {
  //     globalConfig: { defaultPathways },
  //     content
  //   }
  // }
  {
    return (
      <Layout pageType="404">
        {/* <HelmetDatoCms defer={false} seo={content.PageNotFound.meta} />
      <Container>
        <Block padding="double-top" gutters>
          <NotFoundPageIntro
            title={content.PageNotFound.heading}
            lead={content.PageNotFound.lead}
            link={{ displayText: "Return Home", path: "/" }}
            logo={{ src: "/uh-oh.png", alt: "Uh oh" }}
          />
        </Block>
      </Container>
      {defaultPathways && (
        <Block padding="both" gutters>
          <Cards>
            {defaultPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )} */}
      </Layout>
    )
  }

export default NotFoundPage

// export const query = graphql`
//   query NotFoundQuery($language: String!) {
//     globalConfig: datoCmsGlobal(locale: en_AU) {
//       defaultPathways {
//         ...Card
//       }
//     }
//     content: contentYaml {
//       PageNotFound {
//         heading
//         lead
//         meta {
//           tags {
//             tagName
//             content
//             attributes {
//               property
//               content
//             }
//           }
//         }
//       }
//     }
//   }
// `
