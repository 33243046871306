import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ProductContext, { getPricing } from "@context/productContext"
import CartContext from "@context/cartContext"
import Layout from "@layouts/layout"
import {
  Container,
  Customise,
  Block,
  Avante,
  Video,
  Intro,
  Totals,
  Cta,
  Button,
  Upsells
} from "@components"

import {
  isCartItemUpgradable,
  isCartItemAvante,
  avanteSpecific,
  hasAvanteOption
} from "@helpers/avanteHelpers"
import useAddToCart from "@helpers/useAddToCart"

const ProductUpsell = ({ data, pageContext }) => {
  const {
    productLoading,
    setProduct,
    setSku,
    currentModel,
    ready,
    resetProduct
  } = useContext(ProductContext)
  const parentSku = data.product.sku
  const [hasMounted, setHasMounted] = useState(false)
  const [syncedCart, setSyncedCart] = useState(false)
  const cartContext = useContext(CartContext)
  const { cart, cartLoading } = cartContext
  const { addToCart } = useAddToCart()
  const currentItem = !cartLoading && cart.has(parentSku) && cart.get(parentSku)
  const hideAvante = isCartItemAvante(currentItem)
  const isUpgradeable = isCartItemUpgradable(currentItem)
  const parentPrice =
    data.product.netoProduct && getPricing(data.product.netoProduct)
  const hasAvante = hasAvanteOption(data.product)

  useEffect(() => {
    setHasMounted(true)

    return () => {
      setHasMounted(false)
      resetProduct()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const steps = [
    "Review Product Selection",
    "Choose Options and Upgrades",
    "Confirm Your Order"
  ]

  const {
    avanteData,
    avanteThumb,
    avanteUpgradeVideo,
    avanteImage,
    name,
    netoVariants,
    netoProduct,
    netoProductType,
    seoMetaTags,
    usage,
    category,
    ...product
  } = data.product
  const { avanteContent } = data.content
  const defaultVariant = data.defaultVariant || netoVariants[0] || netoProduct
  const { hasUpsells, upsellPath } = pageContext
  const { finance } = netoProduct
  const productData = {
    ...data.product,
    upsellPath,
    hasUpsells,
    parentPrice,
    defaultVariant,
    finance
  }
  useEffect(() => {
    productLoading && ready && setProduct(productData, defaultVariant.sku)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productLoading, ready])

  useEffect(() => {
    if (hasMounted && !productLoading && !cartLoading) {
      const cartProduct = cart.get(product.sku)
      const skuInCart = cartProduct && cartProduct.variantSku
      const currentSku = currentModel.sku
      if (skuInCart !== currentSku) {
        if (syncedCart || !skuInCart) {
          !skuInCart && setSyncedCart(true)
          addToCart(currentModel)
        } else {
          setSyncedCart(true)
          setSku(skuInCart)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLoading, productLoading, currentModel])

  const defaultCheckout = [
    {
      label: "Pay now through secure checkout",
      type: "checkout"
    },
    {
      label: "Email me a quote",
      type: "quote"
    }
  ]

  finance.length > 0 &&
    finance.forEach(item => {
      if (item?.slug === "no-finance") return

      defaultCheckout.push({
        label: item.valueProposition,
        type: item.slug,
        path: item.enquiryFormPage.path
      })
    })

  const showUpsells = product.upsellProducts.length > 0

  return (
    <Layout minimal>
      <Block>
        <Intro title="Shopping Cart" />
      </Block>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {netoProductType === "configurable" && (
        <Block
          background={"teal"}
          type={"section"}
          id={"customise"}
          gutters
          heading={`Step 1 - ${steps[0]}`}
          padding={"double-bottom"}
        >
          <Container>
            <Customise
              {...productData}
              editCart={true}
              heading={`Customise your ${productData.name || "Spa"}`}
            />
          </Container>
        </Block>
      )}

      {hasAvante && !hideAvante && isUpgradeable && (
        <Block
          padding={"double-bottom"}
          heading={avanteContent.heading}
          lead={avanteContent.description}
          type={"section"}
          id={avanteSpecific.slug}
        >
          <Container>
            <Block gutters>
              <Video
                moduleStyle="Full Width"
                imageOption={{ fadeIn: false }}
                image={avanteImage}
                video={avanteUpgradeVideo}
              />
            </Block>

            {avanteData &&
              avanteData.upgrades &&
              avanteData.upgrades.length > 0 && (
                <Block padding={"double-top"}>
                  <Avante
                    productImage={avanteThumb}
                    features={avanteData && avanteData.upgrades}
                    {...productData}
                  />
                </Block>
              )}
          </Container>
        </Block>
      )}

      {showUpsells && (
        <Block
          padding={"double"}
          heading={`Step 2 - ${steps[1]}`}
          type={"section"}
          id={"upgrades"}
          background={"light-gray"}
        >
          <Upsells items={productData.upsellProducts} />
        </Block>
      )}

      <Block
        padding={"double"}
        heading={`Step ${showUpsells ? "3" : "2"} - ${steps[2]}`}
        type={"section"}
        id={"upgrades"}
        background={"blue-swish"}
        gutters
      >
        <Container maxWidth="inner">
          <Totals
            checkoutOptions={defaultCheckout}
            shippingOptions={productData?.shipping?.shippingOptions}
          />
        </Container>
      </Block>
      {finance.length > 0 && <UpsellFinance finance={finance} />}
    </Layout>
  )
}

const UpsellFinance = ({ finance }) => {
  return (
    <Block background={"teal"} gutters>
      <Container maxWidth="inner">
        {finance.length > 1 ? (
          <Cta
            images={[
              {
                ...finance[0].logoFull,
                objectFit: "contain",
                id: finance[0].id
              },
              {
                ...finance[1].logoFull,
                objectFit: "contain",
                id: finance[1].id
              }
            ]}
            message={"Enquire about Finance Options*"}
            button={
              <Button
                short
                white
                secondary
                fullWidth
                to={"/contact-us/finance-enquiry/"}
              >
                {`Enquire now`}
              </Button>
            }
          />
        ) : (
          <Cta
            images={[
              {
                ...finance[0].logoFull,
                objectFit: "contain",
                id: finance[0].id
              }
            ]}
            message={finance[0].valueProposition}
            button={
              <Button
                short
                white
                secondary
                fullWidth
                to={finance[0].enquiryFormPage.path}
              >
                {`Enquire now`}
              </Button>
            }
          />
        )}
      </Container>
    </Block>
  )
}

export const query = graphql`
  query productUpsellById($id: String!, $language: String!, $sku: String!) {
    product: datoCmsProduct(locale: $language, id: { eq: $id }) {
      sku
      path
      ...ProductIntro
      ...Customise
      ...Upsell
      ...AvanteTable
      productOptions
      usage {
        name
        slug
        id
        model {
          apiKey
        }
        __typename
      }
      category {
        id
        slug
        title
      }

      shipping {
        shippingOptions {
          name
          baseRate
          flatRate
          description
        }
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      productFeatures {
        ... on DatoCmsContentWithImage {
          ...ContentWithImage
        }
      }

      netoProduct {
        finance {
          ...FinanceCta
        }
      }
    }
    defaultVariant: netoProduct(
      parentSku: { eq: $sku }
      defaultModel: { eq: true }
    ) {
      ...CustomiseDefaultVariant
    }

    content: contentYaml {
      ...AvanteContent
    }

    globalConfig: datoCmsGlobal(locales: { eq: $language }) {
      contactPage {
        path
      }
    }
  }

  fragment Upsell on DatoCmsProduct {
    upsellProducts {
      sku
      netoProductType
      name
      excerpt
      upsellVideo {
        provider
        providerUid
        thumbnailUrl
        title
        url
      }
      id
      slug
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "262"
            w: "368"
            fit: "crop"
            crop: "entropy"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      position

      brand {
        ...BrandTag
      }

      netoProductType
      netoProduct {
        ...Price
        ...ProductFinance
        ...ProductAggregateSpecs
      }
    }
  }
`

export default ProductUpsell
