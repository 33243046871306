import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import {
  Block,
  Button,
  Blockquote,
  Content,
  Image,
  Video,
  Carousel,
  Grid,
  Cards,
  Card,
  ProductSpecificsGrid,
  Container,
  Contact,
  ProductCard,
  Disclaimer,
  Embed,
  TestimonialList,
  Gallery,
  Badge,
  LocationMapWithFinder,
  Iframe,
  ArPreview,
  Table,
  SplitMediaBlock,
  IconGrid,
  AuthoredBy,
  BlogAccordion,
  BlogCta,
  BlogQuote,
  Reviews
} from "@components"
import { transformProductToCard } from "@helpers/productHelpers"
import { filterByLocale, filterByCountry } from "@helpers/miscHelpers"
import { Banner } from "../partials/components"
import Calculator from "../partials/components/calculator/calculator"
import Suppliers from "../partials/components/suppliers"
import BlurbGrid from "../partials/components/blurb-grid"
import SimpleCardGrid from "../partials/components/simple-card-grid"
import BirdeyeGrid from "../partials/components/birdeye-grid"

export default class ModuleArea extends React.Component {
  getComponent(
    block,
    productName,
    productBrand,
    location,
    isJacuzzi,
    reviews,
    showroom
  ) {
    switch (block.model && block.model.apiKey) {
      case "awards_carousel":
        const awards = block.awards.map(award => {
          if (award.image?.gatsbyImageData) return

          return {
            ...award,
            image: award.image?.url
          }
        })
        return (
          <Carousel
            key={block.id}
            items={awards}
            moduleStyle="awards"
            lead={block.leadNode.childMarkdownRemark.html}
            {...block}
            padding={"None"}
          />
        )
      case "ar_product_preview":
        return (
          <Block gutters>
            <ArPreview {...block} />
          </Block>
        )
      case "suppliers_block":
        return (
          <Block gutters>
            <Suppliers {...block} />
          </Block>
        )
      case "blurb_grid":
        return (
          <Block gutters>
            <BlurbGrid {...block} />
          </Block>
        )
      case "simple_card_grid":
        return (
          <Block gutters>
            <SimpleCardGrid {...block} />
          </Block>
        )
      case "content":
        return (
          <Block gutters>
            <Content key={block.id} {...block} />
          </Block>
        )
      case "content_with_image":
        return (
          <Block gutters>
            <Content type={"image"} key={block.id} {...block} />
          </Block>
        )
      case "featured_products_grid":
        return (
          <Block
            gutters
            heading={block.headingOverride || "Featured Products"}
            lead={block.lead}
          >
            <Block padding={block.lead && "top"}>
              <Cards>
                {block.featuredProduct.map(product => {
                  const productCardConfig = transformProductToCard(product)
                  return (
                    <ProductCard
                      {...productCardConfig}
                      key={`featured-product-${product.name}`}
                    />
                  )
                })}
              </Cards>
            </Block>
          </Block>
        )
      case "media_slider":
        const slides = block.slides.map(
          ({ id, heading, captionNode, image, video, overlayImage, link }) => ({
            id,
            heading,
            caption:
              captionNode.childMarkdownRemark &&
              captionNode.childMarkdownRemark.html &&
              captionNode.childMarkdownRemark.html,
            defaultImage: image && image,
            link: link && {
              title: link.displayText,
              path: link.link ? link.link.path : link.url
            },
            video: video && {
              ...video,
              image: overlayImage
            }
          })
        )

        return (
          <Block
            gutters={block.moduleStyle === "Full Width"}
            background={block.background}
          >
            <Carousel key={block.id} items={slides} {...block} />
            {block.showImageDisclaimer && block.moduleStyle === "Full Width" && (
              <Container>
                <Block padding="both">
                  <Disclaimer
                    children="Image may show optional extras."
                    alignment="left-for-desktop"
                    width={"small"}
                  />
                </Block>
              </Container>
            )}
          </Block>
        )
      case "warranty_slider":
        const warranties = block.warranties.map(
          ({ id, name, featuresNode, badge }) => ({
            id,
            heading: name,
            caption:
              featuresNode.childMarkdownRemark &&
              featuresNode.childMarkdownRemark.html &&
              featuresNode.childMarkdownRemark.html,
            image: badge.url,
            alt: badge.alt
          })
        )

        const warrantyLead =
          block?.leadNode?.childMarkdownRemark?.html ||
          `<p>The ${productBrand} range of spas is covered by comprehensive warranty agreements providing peace of mind when you purchase ${productBrand}.</p>`

        const warrantyDoc = (block.warrantyDocument ||
          block.warrantyDoc.length > 0) && {
          path:
            block.warrantyDoc[0]?.url ||
            block.warrantyDoc[0]?.item.url ||
            block.warrantyDocument.url,
          text: "View Warranty",
          secondary: true
        }

        return (
          <StaticQuery
            query={graphql`
              {
                contentYaml {
                  warranties {
                    heading
                  }
                }
              }
            `}
            render={data => (
              <Block background={block.background}>
                <Carousel
                  key={block.id}
                  items={warranties}
                  {...block}
                  moduleStyle="warranties"
                  heading={data.contentYaml.warranties.heading}
                  topLink={warrantyDoc}
                  lead={warrantyLead}
                />
              </Block>
            )}
          ></StaticQuery>
        )
      case "content_with_video":
        return (
          <Block gutters>
            <Content type={"video"} key={block.id} {...block} />
          </Block>
        )
      case "contact_form":
        return (
          <Block gutters>
            <Contact key={block.id} {...block} location={location} />
          </Block>
        )
      case "image":
        return (
          <Block gutters>
            <Image key={block.id} {...block} standAlone elevated />
          </Block>
        )
      case "video":
        return (
          <Block gutters={!isJacuzzi}>
            <Video key={block.id} {...block} standAlone isJacuzzi={isJacuzzi} />
          </Block>
        )
      case "blockquote":
        return (
          <Block gutters>
            <Blockquote key={block.id} {...block} />
          </Block>
        )
      case "hp_calc":
        return (
          <Block gutters>
            <Container>
              <Calculator key={block.id} {...block} />
            </Container>
          </Block>
        )
      case "tour":
        return (
          <Container>
            <Block gutters heading={productName && `Tour the ${productName}`}>
              <Embed
                placeholder={block.placeholder}
                disclaimer="Tour may show optional extras."
                iframeProps={{
                  id: `plattar-${block.id}`,
                  src: `${block.url}&nt=1&title=0`,
                  width: "100%",
                  height: "100%",
                  frameBorder: "0",
                  allow: `xr-spatial-tracking`,
                  allowFullScreen: `allowFullScreen`,
                  title: block.heading
                }}
                gtm={{
                  event: `embedded-content-engagement`,
                  label: `VR Embed Click`,
                  value: `${block.heading}`
                }}
              ></Embed>
            </Block>
          </Container>
        )
      case "viewer":
        return (
          <Block gutters heading={block.heading}>
            <Embed
              placeholder={block.placeholder}
              disclaimer="Tour may show optional extras."
              iframeProps={{
                id: `plattar-${block.id}`,
                src: `https://app.plattar.com/webgleditor/preview/index.html?sceneId=${block.scene}`,
                width: "100%",
                height: "100%",
                frameBorder: "0",
                allow: `"camera; vr; autoplay; xr-spatial-tracking"`,
                allowFullScreen: `allowFullScreen`,
                title: block.heading
              }}
              gtm={{
                event: `embedded-content-engagement`,
                label: `VR Embed Click`,
                value: `${block.heading}`
              }}
            ></Embed>
          </Block>
        )
      case "carousel":
        const carouselItems = block.carouselItem.map(
          ({ id, heading, captionNode, image, link }) => ({
            id,
            heading,
            caption:
              captionNode.childMarkdownRemark &&
              captionNode.childMarkdownRemark.html &&
              captionNode.childMarkdownRemark.html,
            defaultImage: image,
            link: link && {
              title: link.displayText,
              path: link.link ? link.link.path : link.url
            }
          })
        )

        return (
          <Block gutters>
            <Carousel key={block.id} items={carouselItems} {...block} />
          </Block>
        )
      case "badge_carousel":
        return (
          <Carousel
            key={block.id}
            items={block.badges}
            moduleStyle="badges"
            lead={block.leadNode.childMarkdownRemark.html}
            {...block}
          />
        )
      case "badge_list":
        return (
          <Cards
            key={block.id}
            orientation="horizontal"
            columns="auto"
            size="large"
          >
            {block.badges &&
              block.badges.length > 0 &&
              block.badges.map(
                ({ image: { url, alt, gatsbyImageData }, id }) => (
                  <Badge
                    image={gatsbyImageData || url}
                    alt={alt}
                    key={`badge-${id}`}
                    moduleStyle="width-auto"
                  />
                )
              )}
          </Cards>
        )
      case "testimonial_slider":
        return (
          <Carousel
            key={block.id}
            items={block.testimonials}
            moduleStyle="testimonial"
            heading={block.heading}
            {...block}
            isJacuzzi={isJacuzzi}
          />
        )
      case "testimonials_list":
        return <TestimonialList key={block.id} {...block} location={location} />
      case "image_slider":
        const items = block.slides.map(
          ({ id, heading, captionNode, image, link }) => ({
            id,
            heading,
            caption: captionNode.childMarkdownRemark.html,
            defaultImage: image,
            link: link && {
              title: link.displayText,
              path: link.link?.path || link.url
            }
          })
        )

        return (
          <Block background={block.background}>
            <Carousel key={block.id} items={items} {...block} />
          </Block>
        )
      case "authored_by":
        return (
          <Block>
            <AuthoredBy {...block} />
          </Block>
        )
      case "review":
        return (
          <Block>
            <Reviews
              {...block}
              reviews={reviews}
              fullBlock={false}
              location={showroom}
            />
          </Block>
        )
      case "blog_post_grid":
        const blogItems = filterByLocale(block.blogPost)
        return (
          <Block background={block.background}>
            <Grid
              key={block.id}
              {...block}
              linkText={block?.link?.displayText}
              secondaryButton={block.background === "Splash" ? true : false}
              moduleStyle="blog-post-grid"
            >
              {blogItems.map(item => {
                const { title, excerptNode, heroImage, path, id } = item
                return (
                  <Card
                    heading={title}
                    image={heroImage}
                    caption={excerptNode.childMarkdownRemark.html}
                    link={{
                      title: "Read More",
                      path
                    }}
                    key={`card-${id}`}
                    elevated
                    truncate={3}
                  />
                )
              })}
            </Grid>
          </Block>
        )
      case "blog_accordion":
        return (
          <Block padding={block.padding}>
            <BlogAccordion {...block} />
          </Block>
        )
      case "blog_cta":
        return (
          <Block padding={block.padding}>
            <BlogCta {...block} />
          </Block>
        )
      case "blog_quote":
        return (
          <Block padding={block.padding}>
            <BlogQuote {...block} />
          </Block>
        )
      case "page_grid":
        return (
          <Block background={block.background}>
            <Grid key={block.id} {...block} linkText={block.link.displayText}>
              {block.page.map(item => {
                const { title, excerptNode, heroImage, path, id } = item

                return (
                  <Card
                    heading={title}
                    image={heroImage}
                    caption={excerptNode.childMarkdownRemark.html}
                    link={{
                      title: "More",
                      path
                    }}
                    key={`card-${id}`}
                    elevated
                    truncate={3}
                  />
                )
              })}
            </Grid>
          </Block>
        )
      case "collections_grid":
        return (
          <Grid key={block.id} {...block}>
            {block.collections.map(item => {
              const {
                name,
                id,
                image,
                excerptNode,
                salesFeatures,
                path,
                tag,
                link
              } = item
              const brand = tag?.model?.apiKey === "brand" ? tag : null

              let customlink

              if (link) {
                customlink = link.link?.path
              } else {
                customlink = path
              }

              return (
                <Card
                  heading={name}
                  key={`product-landing-${id}`}
                  image={image}
                  caption={excerptNode.childMarkdownRemark.html}
                  salesFeatures={salesFeatures}
                  brand={brand}
                  link={{ path: customlink, url: link?.url }}
                  elevated
                  hover
                  truncate={block.simple ? 3 : 2}
                  simple={block.simple}
                />
              )
            })}
          </Grid>
        )
      case "featured_collections_grid":
        return (
          <>
            <Block gutters heading={block.heading}>
              {""}
            </Block>
            <Cards
              key={block.id}
              {...block}
              orientation="horizontal"
              modifier="featured-collections"
            >
              {block.collections.map(item => {
                const { name, id, image, excerptNode, path, tag } = item
                const brand = tag?.model?.apiKey === "brand" ? tag : null

                return (
                  <Card
                    heading={name}
                    key={`product-landing-${id}`}
                    image={image}
                    caption={excerptNode.childMarkdownRemark.html}
                    brand={brand}
                    link={{ path: path }}
                    smallForMobile
                    elevated
                    hover
                  />
                )
              })}
            </Cards>
            {block.link && (
              <Block gutters>
                <Button
                  to={block.link.link ? block.link.link.path : block.link.url}
                >
                  {block.link.displayText}
                </Button>
              </Block>
            )}
          </>
        )
      case "people_grid":
        const people = filterByCountry(block.people)

        return (
          <>
            {people.length > 0 && (
              <Grid key={block.id} {...block} isJacuzzi={isJacuzzi}>
                {people.map(item => {
                  const { id, name, jobTitle, captionNode, video } = item
                  return (
                    <Card
                      heading={name}
                      subHeading={jobTitle}
                      video={video}
                      caption={captionNode.childMarkdownRemark.html}
                      {...item}
                      key={`person-${id}`}
                      borderless
                      isJacuzzi={isJacuzzi}
                    />
                  )
                })}
              </Grid>
            )}
          </>
        )
      case "birdeye_review_grid":
        return <BirdeyeGrid {...block} />
      case "awards_grid":
        const awardsArr = block.awards
        return (
          <>
            {awardsArr.length > 0 && (
              <Grid
                key={block.id}
                {...block}
                divider={block.lead ? false : true}
                moduleStyle={block.moduleStyle.replace(" ", "-")}
              >
                {awardsArr.map(item => {
                  const { id, image, heading } = item
                  return (
                    <Card
                      image={image?.url}
                      heading={heading}
                      key={id}
                      borderless
                      smallHeading
                    />
                  )
                })}
              </Grid>
            )}
          </>
        )
      case "downloads_grid":
        const downloadsArr = block.downloads
        return (
          <>
            {downloadsArr.length > 0 && (
              <Grid
                key={block.id}
                {...block}
                divider={block.lead ? false : true}
                moduleStyle={block.moduleStyle}
              >
                {downloadsArr.map(item => {
                  const { id, image, heading, file, text, fileOverride } = item
                  const newFile =
                    fileOverride.length > 0
                      ? {
                          url: fileOverride[0]?.url || fileOverride[0]?.item.url
                        }
                      : file

                  return (
                    <Card
                      image={image}
                      heading={heading}
                      key={id}
                      borderless
                      smallHeading
                      file={newFile}
                      caption={text}
                    />
                  )
                })}
              </Grid>
            )}
          </>
        )
      case "gallery":
        return (
          <Container>
            <Gallery
              items={block.galleryItems}
              showFilters={block.showFilters}
              location={location}
            />
          </Container>
        )
      case "product_specifics_grid":
        const { defaultVariant } = this.props
        return (
          <ProductSpecificsGrid {...block} defaultVariant={defaultVariant} />
        )
      case "button":
        return (
          <Block gutters>
            <Button
              to={block?.link?.path ? block.link.path : block.url}
              isNewTab={!!block.openInNewTab || block.newWindow}
              hash={block?.locationHash}
              noFollow={block.noFollow}
            >
              {block.displayText}
            </Button>
          </Block>
        )
      case "banner":
        return (
          <Container>
            <Block gutters padding={"double-bottom"} maxWidth={"content"}>
              <Banner
                image={block.item[0]?.image}
                alt={block.item[0]?.image?.alt}
                mobileImage={block.item[0].mobileImage}
                link={
                  block.item[0]?.link?.link?.path || block.item[0]?.link?.url
                }
                elevated
              />
            </Block>
          </Container>
        )
      case "embed":
        return (
          <Block gutters>
            <Iframe
              id={`embed-${block.id}`}
              src={block.embed}
              frameBorder={"0"}
              title={block.title}
              resize
              allowFullScreen
            />
          </Block>
        )
      case "table":
        const transformTableData = field => {
          let tableData = block[field].map(({ tableCells }) => {
            const data = tableCells.map(({ content }) => {
              if (field === "tableHead") {
                return <h3 dangerouslySetInnerHTML={{ __html: content }} />
              } else {
                return <div dangerouslySetInnerHTML={{ __html: content }} />
              }
            })

            return [...data]
          })

          return tableData
        }

        const tableHeadData = transformTableData("tableHead")
        const tableBodyData = transformTableData("tableBody")

        return (
          <Container>
            <Table
              id={`table-${block.id}`}
              head={[...tableHeadData]}
              body={[...tableBodyData]}
              firstColumnHeader={block.firstColumnHeader}
              modifier="specs"
            />
          </Container>
        )
      case "split_media_block":
        return (
          <Block gutters padding={block.padding}>
            <Container>
              <SplitMediaBlock blocks={block.blocks} />
            </Container>
          </Block>
        )
      case "icon_grid":
        return (
          <Block padding={block.padding}>
            <Container>
              <IconGrid items={block.items} />
            </Container>
          </Block>
        )
      default:
        return (
          <center>
            {`Module Type: `}
            <code>{block.model && block.model.apiKey}</code>
            {` is not supported.`}
          </center>
        )
    }
  }

  render() {
    const {
      modules,
      productName,
      productBrand,
      location,
      isJacuzzi = false,
      reviews,
      showroom
    } = this.props

    return modules.map(block => {
      return (
        <Block
          key={`parent-${block.id}`}
          linkHash={block.linkHash}
          padding={block.padding}
        >
          {this.getComponent(
            block,
            productName,
            productBrand,
            location,
            isJacuzzi,
            reviews,
            showroom
          )}
        </Block>
      )
    })
  }
}

ModuleArea.propTypes = {
  modules: PropTypes.array.isRequired
}

export const query = graphql`
  fragment PageContentModuleArea on DatoCmsPage {
    __typename
    content {
      ... on DatoCmsAwardsGrid {
        ...AwardsGrid
      }
      ... on DatoCmsBlurbGrid {
        ...BlurbGrid
      }
      ... on DatoCmsSimpleCardGrid {
        ...SimpleCardGrid
      }
      ... on DatoCmsDownloadsGrid {
        ...DownloadsGrid
      }
      ... on DatoCmsEmbed {
        ...FormIframe
      }
      ... on DatoCmsAwardsCarousel {
        ...AwardsCarousel
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsMediaSlider {
        ...MediaSlider
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsCarousel {
        ...Carousel
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsBlockquote {
        ...Blockquote
      }
      ... on DatoCmsBadgeCarousel {
        ...BadgeCarousel
      }
      ... on DatoCmsBlogPostGrid {
        ...BlogPostGrid
      }
      ... on DatoCmsPageGrid {
        ...PageGrid
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsPeopleGrid {
        ...PeopleGrid
      }
      ... on DatoCmsContactForm {
        ...Contact
      }
      ... on DatoCmsContentWithImage {
        ...ContentWithImage
      }
      ... on DatoCmsTestimonialsList {
        ...TestimonialList
      }
      ... on DatoCmsGallery {
        ...Gallery
      }
      ... on DatoCmsArProductPreview {
        ...ArPreview
      }
      ... on DatoCmsTour {
        ...TourEmbed
      }
      ... on DatoCmsTable {
        ...Table
      }
      ... on DatoCmsSplitMediaBlock {
        ...SplitMediaBlock
      }
      ... on DatoCmsIconGrid {
        ...IconGrid
      }
      ... on DatoCmsBanner {
        __typename
        id
        model {
          apiKey
        }
        item {
          id
          __typename
          link {
            ...Link
          }
          image {
            url
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "295"
                w: "1170"
                fit: "crop"
                q: 60
                auto: "format"
              }
            )
            alt
          }
          mobileImage {
            url
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "175"
                w: "420"
                fit: "cover"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
      }
      ... on DatoCmsBirdeyeReviewGrid {
        __typename
        id
        model {
          apiKey
        }
      }
      ... on DatoCmsSuppliersBlock {
        __typename
        id
        model {
          apiKey
        }
        items {
          id
          preferred
          name
          state
          city
          industry
          phone
          website
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                w: "375"
                fit: "crop"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
      }
    }
  }

  fragment LandingPageContentModuleArea on DatoCmsLandingPage {
    __typename
    contentWithForm {
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsContactForm {
        ...Contact
      }
      ... on DatoCmsEmbed {
        ...FormIframe
      }
    }

    content {
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsBlurbGrid {
        ...BlurbGrid
      }
      ... on DatoCmsSimpleCardGrid {
        ...SimpleCardGrid
      }
      ... on DatoCmsMediaSlider {
        ...MediaSlider
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsCarousel {
        ...Carousel
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsBlockquote {
        ...Blockquote
      }
      ... on DatoCmsBadgeCarousel {
        ...BadgeCarousel
      }
      ... on DatoCmsBadgeList {
        ...BadgeList
      }
      ... on DatoCmsBlogPostGrid {
        ...BlogPostGrid
      }
      ... on DatoCmsPageGrid {
        ...PageGrid
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsPeopleGrid {
        ...PeopleGrid
      }
      ... on DatoCmsContactForm {
        ...Contact
      }
      ... on DatoCmsContentWithImage {
        ...ContentWithImage
      }
      ... on DatoCmsLocationMap {
        ...LocationMap
      }
      ... on DatoCmsTable {
        ...Table
      }
      ... on DatoCmsCollectionsGrid {
        ...CollectionsGrid
      }
    }
  }

  fragment PostPurchaseContentModuleArea on DatoCmsPostPurchase {
    __typename
    content {
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsMediaSlider {
        ...MediaSlider
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsCarousel {
        ...Carousel
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsBlockquote {
        ...Blockquote
      }
      ... on DatoCmsBadgeCarousel {
        ...BadgeCarousel
      }
      ... on DatoCmsBlogPostGrid {
        ...BlogPostGrid
      }
      ... on DatoCmsPageGrid {
        ...PageGrid
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsPeopleGrid {
        ...PeopleGrid
      }
      ... on DatoCmsContactForm {
        ...Contact
      }
    }
  }

  fragment HomeContentModuleArea on DatoCmsHome {
    __typename
    content {
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsBlogPostGrid {
        ...BlogPostGrid
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsBlockquote {
        ...Blockquote
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsFeaturedCollectionsGrid {
        ...FeaturedCollectionsGrid
      }
      ... on DatoCmsTestimonialSlider {
        ...TestimonialSlider
      }
      ... on DatoCmsTable {
        ...Table
      }
    }
  }

  fragment LocationContentModuleArea on DatoCmsLocation {
    __typename
    content {
      ... on DatoCmsAwardsCarousel {
        ...AwardsCarousel
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsPeopleGrid {
        ...PeopleGrid
      }
      ... on DatoCmsTestimonialSlider {
        ...TestimonialSlider
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsTable {
        ...Table
      }
      ... on DatoCmsFeaturedProductsGrid {
        ...FeaturedProductsGrid
      }
      ... on DatoCmsEmbed {
        ...FormIframe
      }
    }
  }

  fragment ShowroomListContentModuleArea on DatoCmsShowroomList {
    __typename
    content {
      ... on DatoCmsAwardsCarousel {
        ...AwardsCarousel
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsFeaturedCollectionsGrid {
        ...FeaturedCollectionsGrid
      }
      ... on DatoCmsCollectionsGrid {
        ...CollectionsGrid
      }
      ... on DatoCmsFeaturedProductsGrid {
        ...FeaturedProductsGrid
      }
      ... on DatoCmsContactForm {
        ...Contact
      }
      ... on DatoCmsTable {
        ...Table
      }
    }
  }

  fragment BlogContentModuleArea on DatoCmsBlogPost {
    __typename
    content {
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsBlockquote {
        ...Blockquote
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsBlogPostGrid {
        ...BlogPostGrid
      }
      ... on DatoCmsTable {
        ...Table
      }
      ... on DatoCmsEmbed {
        ...FormIframe
      }
      ... on DatoCmsBlogAccordion {
        ...BlogAccordion
      }
      ... on DatoCmsBlogCta {
        ...BlogCta
      }
      ... on DatoCmsBlogQuote {
        ...BlogQuote
      }
      ... on DatoCmsHpCalc {
        __typename
        id
        padding
        info
        model {
          apiKey
        }
      }
    }
    ...AuthoredBy
  }

  fragment ProductSpecificsModuleArea on DatoCmsProduct {
    __typename
    productSpecificsComparison {
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsProductSpecificsGrid {
        ...SpecificsGrid
      }
    }
  }

  fragment ProductLandingModuleArea on DatoCmsProductLandingPage {
    __typename
    content {
      ... on DatoCmsCollectionsGrid {
        ...CollectionsGrid
      }
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsSimpleCardGrid {
        ...SimpleCardGrid
      }
      ... on DatoCmsFeaturedProductsGrid {
        ...FeaturedProductsGrid
      }
      ... on DatoCmsBlogPostGrid {
        ...BlogPostGrid
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
    }
  }

  fragment ProductDescriptionModuleArea on DatoCmsProduct {
    __typename
    productDescription {
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsButton {
        ...Button
      }
      ... on DatoCmsTour {
        ...TourEmbed
      }
      ... on DatoCmsMediaSlider {
        ...MediaSlider
      }
      ... on DatoCmsViewer {
        ...ViewerEmbed
      }
    }
  }

  fragment ProductFeaturesModuleArea on DatoCmsProduct {
    __typename
    productFeatures {
      ... on DatoCmsContentWithImage {
        ...ContentWithImage
      }
      ... on DatoCmsContentWithVideo {
        ...ContentWithVideo
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsMediaSlider {
        ...MediaSlider
      }
      ... on DatoCmsViewer {
        ...ViewerEmbed
      }
    }
  }

  fragment ProductWarrantiesModuleArea on DatoCmsProduct {
    __typename
    productWarranties {
      ... on DatoCmsWarrantySlider {
        ...WarrantySlider
      }
    }
  }

  fragment ProductCollectionModuleArea on DatoCmsCollection {
    __typename
    datoContent: content {
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsImage {
        ...Image
      }
    }
  }

  fragment ShowroomMainLandingModuleArea on DatoCmsShowroomMainLanding {
    __typename
    content {
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsContentWithImage {
        ...ContentWithImage
      }
      ... on DatoCmsContentWithVideo {
        ...ContentWithVideo
      }
      ... on DatoCmsGallery {
        ...Gallery
      }
      ... on DatoCmsImage {
        ...Image
      }
      ... on DatoCmsImageSlider {
        ...CarouselImageSlide
      }
      ... on DatoCmsMediaSlider {
        ...MediaSlider
      }
      ... on DatoCmsVideo {
        ...Video
      }
    }
  }
`
