import React from "react"
import { graphql } from "gatsby"
import { Intro, Block } from "@components"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ModuleArea from "../helpers/moduleArea"

const Specials = ({ data, pageContext }) => {
  const { globalConfig } = data

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: globalConfig.specialsHeading,
    lead: globalConfig.specialsLead,
    showHeroImage: false
  }

  const meta = { tags: [] }

  for (const [key, value] of Object.entries(
    globalConfig.specialsPageMetaTags
  )) {
    if (!value) continue

    if (key === "title") {
      meta.tags.push(
        {
          tagName: key,
          content: value
        },
        {
          tagName: "meta",
          attributes: { property: `og:${key}`, content: value }
        },
        {
          tagName: "meta",
          attributes: { name: `twitter:${key}`, content: value }
        }
      )
    }

    if (key === "description") {
      meta.tags.push(
        {
          tagName: "meta",
          attributes: { name: `${key}`, content: value }
        },
        {
          tagName: "meta",
          attributes: { property: `og:${key}`, content: value }
        },
        {
          tagName: "meta",
          attributes: { name: `twitter:${key}`, content: value }
        }
      )
    }

    if (key === "twitterCard") {
      meta.tags.push({
        tagName: "meta",
        attributes: { name: `twitter:card`, content: value }
      })
    }

    if (key === "image") {
      meta.tags.push(
        {
          tagName: "meta",
          attributes: { property: `og:${key}`, content: value.url }
        },
        {
          tagName: "meta",
          attributes: { name: `twitter:${key}`, content: value.url }
        }
      )
    }
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={meta} />
      <Block gutters>
        <Intro {...introConfig} />
      </Block>
      <ModuleArea modules={globalConfig.specials} />
    </Layout>
  )
}

export default Specials

export const query = graphql`
  query specialsByLanguage($language: String!) {
    globalConfig: datoCmsGlobal(locales: { eq: $language }) {
      contactPage {
        path
      }

      specialsPageMetaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }

      specials {
        ... on DatoCmsContentWithImage {
          ...ContentWithImage
        }
        ... on DatoCmsContent {
          ...Content
        }
      }
      specialsHeading
      specialsLead
    }
  }
`
