import React from "react"
import style from "./grid.mod.scss"
import { Lead, Button, Superscript } from "@components"
import { graphql } from "gatsby"
import { AnimateSharedLayout, motion } from "framer-motion"

const Grid = ({
  heading = false,
  lead,
  children,
  link,
  headingLevel = 2,
  moduleStyle = "default",
  linkText = "More",
  background = "None",
  leadNode,
  divider,
  secondaryButton = false,
  isJacuzzi = false,
  simple = false
}) => {
  const modifiers = [
    style[
      "grid--bg-" +
        background
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ],
    style[
      "grid--" +
        moduleStyle
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ]
  ]

  divider && modifiers.push(style["grid--divider"])
  isJacuzzi && modifiers.push(style["grid--jacuzzi"])
  simple && modifiers.push(style["grid--3-up-grid"])

  const buttonConfig = {
    to: link?.path || link?.url,
    children: linkText
  }

  const Heading = () => {
    const config = {
      className: style.grid__heading,
      children: <Superscript children={heading} />
    }
    switch (headingLevel) {
      case 4:
        return <h4 {...config.className}>{config.children}</h4>
      case 3:
        return <h3 {...config.className}>{config.children}</h3>
      case 2:
      default:
        return <h2 {...config.className}>{config.children}</h2>
    }
  }
  return (
    <div className={[style.grid, ...modifiers].join(" ")}>
      {(heading || leadNode) && (
        <div className={style.grid__header}>
          {heading && <Heading />}
          {leadNode && <Lead body={leadNode.childMarkdownRemark.html} />}
          {lead && <Lead body={`<p>${lead}</p>`} />}
        </div>
      )}
      <div className={style.grid__wrapper}>{children}</div>
      {link && (
        <div className={style.grid__button}>
          <Button
            {...buttonConfig}
            fullWidthMobile
            secondary={secondaryButton}
          />
        </div>
      )}
    </div>
  )
}

export default Grid

export const query = graphql`
  fragment BlogPostGrid on DatoCmsBlogPostGrid {
    id
    heading
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    padding
    background
    moduleStyle
    link {
      ...Link
    }
    model {
      apiKey
    }
    linkHash
    __typename
    blogPost {
      ...BlogCard
    }
  }

  fragment PageGrid on DatoCmsPageGrid {
    id
    heading
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    padding
    background
    moduleStyle
    link {
      ...Link
    }
    model {
      apiKey
    }
    linkHash
    __typename
    page {
      ...PageCard
    }
  }

  fragment AwardsGrid on DatoCmsAwardsGrid {
    id
    heading
    lead
    moduleStyle
    padding
    model {
      apiKey
    }
    linkHash
    __typename
    awards {
      ...AwardCard
    }
  }

  fragment DownloadsGrid on DatoCmsDownloadsGrid {
    id
    heading
    lead
    moduleStyle
    padding
    model {
      apiKey
    }
    linkHash
    __typename
    downloads {
      ...DownloadCard
    }
  }

  fragment PeopleGrid on DatoCmsPeopleGrid {
    id
    heading
    leadNode {
      childMarkdownRemark {
        html
      }
    }
    padding
    moduleStyle
    model {
      apiKey
    }
    linkHash
    __typename
    people {
      ...PeopleCard
    }
  }

  fragment CollectionsGrid on DatoCmsCollectionsGrid {
    id
    heading
    padding
    simple
    model {
      apiKey
    }
    linkHash
    __typename
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    collections {
      ... on DatoCmsCollection {
        id
        name
        path
        excerptNode {
          childMarkdownRemark {
            html
          }
        }
        slug
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "357"
              fit: "crop"
              w: "501"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        model {
          apiKey
        }
        __typename
        salesFeatures {
          description
          id
        }
        tag {
          ... on DatoCmsBrand {
            ...BrandTag
          }
          ... on DatoCmsUsage {
            ...UsageTag
          }
          ... on DatoCmsSize {
            ...SizeTag
          }
          ... on DatoCmsPrice {
            ...PriceTag
          }
          ... on DatoCmsTag {
            ...TagTag
          }
        }
      }
      ... on DatoCmsCustomCollection {
        id
        name
        excerptNode {
          childMarkdownRemark {
            html
          }
        }
        link {
          ...Link
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "357"
              fit: "crop"
              w: "501"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        model {
          apiKey
        }
        __typename
        salesFeatures {
          description
          id
        }
      }
    }
  }
  fragment FeaturedCollectionsGrid on DatoCmsFeaturedCollectionsGrid {
    heading
    padding
    link {
      ...Link
    }
    model {
      apiKey
    }
    linkHash
    __typename
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    collections {
      ...CollectionCard
    }
  }
  fragment FeaturedProductsGrid on DatoCmsFeaturedProductsGrid {
    id
    model {
      apiKey
    }
    linkHash
    __typename
    padding
    headingOverride
    lead
    featuredProduct {
      ...ProductCard
    }
  }
  fragment BrandTag on DatoCmsBrand {
    id
    name
    slug
    model {
      apiKey
    }
    __typename
    logo {
      url
      alt
    }
  }
  fragment UsageTag on DatoCmsUsage {
    id
    name
    slug
    model {
      apiKey
    }
    __typename
  }
  fragment SizeTag on DatoCmsSize {
    id
    name
    slug
    model {
      apiKey
    }
    __typename
  }
  fragment PriceTag on DatoCmsPrice {
    id
    name
    slug
    model {
      apiKey
    }
    __typename
  }
  fragment TagTag on DatoCmsTag {
    id
    name
    slug
    model {
      apiKey
    }
    __typename
  }
  fragment SpecificsGrid on DatoCmsProductSpecificsGrid {
    id
    specificName
    padding
    model {
      apiKey
    }
    linkHash
    __typename
    cards {
      ...SpecificsCard
    }
  }
`
