import React from "react"
import { graphql } from "gatsby"
import style from "./badge.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Superscript } from "@components"

const Badge = ({
  image,
  heading,
  caption = "",
  moduleStyle = "default",
  alt,
  crop = "none"
}) => {
  const modifiers = [
    style["badge--" + moduleStyle.toLowerCase().replace(/ /gi, "-")],
    style["badge--" + crop.toLowerCase().replace(/ /gi, "-")]
  ]

  const isSvg = typeof image === "string" && image.search(/.svg/g) > -1

  return (
    <div className={[style.badge, ...modifiers].join(" ")}>
      <div className={style.badge__image}>
        {isSvg ? (
          <img src={image} alt={alt || heading} />
        ) : (
          <GatsbyImage image={image.gatsbyImageData} />
        )}
      </div>
      <div className={style.badge__content}>
        {heading && (
          <h4
            className={style.badge__heading}
            children={<Superscript children={heading} />}
          />
        )}
        {caption && (
          <div
            className={style.badge__caption}
            children={<Superscript children={caption} />}
          />
        )}
      </div>
    </div>
  )
}

export default Badge

export const query = graphql`
  fragment Badge on DatoCmsBadge {
    id
    heading
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { w: "200", fit: "crop", auto: "format", q: 50 }
      )
      alt
      url
    }
    model {
      apiKey
    }
    __typename
  }

  fragment Award on DatoCmsBadge {
    id
    heading
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { w: "206", fit: "crop", auto: "format", q: 50 }
      )
      alt
      url
    }

    model {
      apiKey
    }
    __typename
  }

  fragment TrustMark on DatoCmsTrustMark {
    id
    heading
    icon {
      alt
      url
    }
    model {
      apiKey
    }
    __typename
  }

  fragment Warranty on DatoCmsWarranty {
    id
    name
    featuresNode {
      childMarkdownRemark {
        html
      }
    }
    badge {
      alt
      url
    }
    model {
      apiKey
    }
    __typename
  }
`
