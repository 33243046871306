import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Intro, Cards, Card, Block, Container } from "@components"
import Layout from "@layouts/layout"
import ModuleArea from "../helpers/moduleArea"
import SearchBar from "../partials/components/search/search-bar"
import LearnCentreSearchContext from "@context/learnCentreSearchContext"
import { InstantSearch } from "react-instantsearch-dom"
import CategoryCards from "../partials/elements/category-cards"
import LearnCentreNavigation from "../partials/elements/learn-centre-navigation"
import FeaturedCards from "../partials/elements/featured-cards"

const LearnCategoryPage = props => {
  const { data, location, pageContext } = props
  const {
    title,
    lead,
    seo,
    content,
    pathways,
    showPathways,
    featuredItems
  } = data.page

  const context = useContext(LearnCentreSearchContext)
  const allPathways = [...pathways, ...data.globalConfig.defaultPathways].slice(
    0,
    3
  )
  const learnContent = data.pageContent.nodes[0]
  const categories = data.subcategories.nodes

  const introConfig = {
    breadcrumbs: pageContext.breadcrumbs,
    title: title,
    lead: lead
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seo} />
      <Block gutters>
        <Intro {...introConfig} />
      </Block>

      <InstantSearch
        searchClient={context.searchClient}
        indexName={context.index.name}
        onSearchStateChange={context.onSearchStateChange}
        createURL={context.createURL}
        searchState={context.searchState}
      >
        <Container maxWidth={"inner"}>
          <Block gutters padding={`double-bottom`} relative>
            <SearchBar
              context={{ ...context }}
              suggested={learnContent.popularTopics}
            />
          </Block>
        </Container>
        <LearnCentreNavigation breadcrumbs={introConfig.breadcrumbs} />
      </InstantSearch>
      <CategoryCards categories={categories} />
      <FeaturedCards items={featuredItems} pageSize={6} featured />
      {content && (
        <Block>
          <CategoryCards />
          <ModuleArea modules={content} location={location} />
        </Block>
      )}
      {showPathways && (
        <Block padding="both" gutters>
          <Cards>
            {allPathways.map(pathway => {
              const { link, displayText, url } = pathway.link
              return (
                <Card
                  key={`pathway-${pathway.id}`}
                  {...pathway}
                  link={{
                    path: link && link.path,
                    title: displayText,
                    url: url && url
                  }}
                  pathway
                  elevated
                  truncate={3}
                />
              )
            })}
          </Cards>
        </Block>
      )}
    </Layout>
  )
}

export const query = graphql`
  query categoryPageById($id: String!, $language: String!) {
    page: datoCmsLearnCategory(locale: $language, id: { eq: $id }) {
      id
      title
      slug
      lead
      internal {
        type
      }
      showPathways
      pathways {
        id
      }
      seo: seoMetaTags {
        tags
      }
      featuredItems {
        ... on DatoCmsVideoLibraryItem {
          id
          heading
          caption
          meta {
            createdAt
          }
          overlayImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "302"
                w: "557"
                fit: "crop"
                q: 60
                auto: "format"
              }
            )
          }
          video {
            url
            title
            provider
            providerUid
            thumbnailUrl
            width
            height
          }
        }
        ... on DatoCmsBlogPost {
          id
          title
          excerpt
          path
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "302"
                w: "557"
                fit: "crop"
                q: 60
                auto: "format"
              }
            )
          }
        }
      }
    }
    pageContent: allDatoCmsPage(
      locale: $language
      filter: { slug: { eq: "learn" }, locales: { eq: $language } }
    ) {
      nodes {
        slug
        title
        lead
        seo: seoMetaTags {
          tags
        }
        popularTopics {
          ... on DatoCmsBlogPost {
            id
            path
            title
          }
          ... on DatoCmsPage {
            id
            path
            title
          }
        }
      }
    }
    subcategories: allDatoCmsLearnSubcategory(
      locale: $language
      filter: { parentCategory: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        parentCategory {
          title
        }
        id: originalId
        slug
        name
        cardImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "148"
              w: "370"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
        }
        cardIntroText
      }
    }
    tags: allDatoCmsTag(
      locale: $language
      filter: { slug: { ne: "specials" }, locales: { eq: $language } }
    ) {
      nodes {
        id
        name
        slug
        position
      }
    }
    globalConfig: datoCmsGlobal(locales: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`
export default LearnCategoryPage
