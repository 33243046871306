import React, { useState, useEffect } from "react"
import cn from "classnames"
import style from "./calculator.mod.scss"
import Select from "../../elements/select"
import Papa from "papaparse"
import { useStaticQuery, graphql } from "gatsby"

// Function to fetch and parse CSV data from DatoCMS
const fetchCsvData = async (filePath, skipFirstRow = false) => {
  const response = await fetch(filePath)
  if (!response.ok) {
    throw new Error(`Failed to fetch ${filePath}: ${response.statusText}`)
  }
  const reader = response.body.getReader()
  const result = await reader.read()
  const decoder = new TextDecoder("utf-8")
  const csv = decoder.decode(result.value)
  return new Promise((resolve, reject) => {
    Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        let data = results.data
        if (skipFirstRow) {
          data = data.slice(1)
        }
        resolve(data)
      },
      error: error => reject(error)
    })
  })
}

const Calculator = ({ info }) => {
  const [modelsData, setModelsData] = useState([])
  const [heatPumpsData, setHeatPumpsData] = useState({})
  const [locationsData, setLocationsData] = useState({})
  const [selectedModel, setSelectedModel] = useState("")
  const [selectedLocation, setSelectedLocation] = useState("")
  const [selectedHeatPump, setSelectedHeatPump] = useState("")
  const [selectedHeatPumpData, setSelectedHeatPumpData] = useState(null)

  const data = useStaticQuery(graphql`
    {
      allDatoCmsGlobal {
        nodes {
          heatPumpModels {
            url
          }
          locations {
            name
            sheet {
              url
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const locale = process.env.GATSBY_LANGUAGE || "en-AU"
        const localeData = data.allDatoCmsGlobal.nodes.find(
          node => node.locale === locale
        )

        const models = await fetchCsvData(localeData.heatPumpModels.url)
        setModelsData(
          models.map(model => ({
            brand: model["Brand"] || model[""], // Adjust this based on the actual CSV header
            model: model["Model"],
            litres: parseFloat(model["Volume"]),
            recommendedHeatPumps: model["Recomended Heat Pump"]
              ?.split(",")
              .map(hp => hp.trim()),
            sku: model["SKU"]
          }))
        )

        const fetchHeatPumpsForLocation = async location => {
          const data = await fetchCsvData(location.sheet.url, true)
          const rrpKey = Object.keys(data[0]).find(key => key.includes("RRP"))
          return data.reduce((acc, pump) => {
            const key = pump["SKU"]
            if (key) {
              acc[key] = {
                model: pump["HEATER TYPE"],
                roi: pump["ROI"],
                rrp: pump[rrpKey]?.replace(/,/g, ""),
                "1_year": pump["1 YEAR"],
                "2_year": pump["2 YEAR"],
                "3_year": pump["3 YEAR"],
                "5_year": pump["5 YEAR"],
                "10_year": pump["10 YEAR"],
                sku: pump["SKU"]
              }
            }
            return acc
          }, {})
        }

        const heatPumpsData = await Promise.all(
          localeData.locations.map(async location => {
            const data = await fetchHeatPumpsForLocation(location)
            return { [location.name]: data }
          })
        ).then(results =>
          results.reduce((acc, data) => ({ ...acc, ...data }), {})
        )

        const locationsData = localeData.locations.reduce((acc, location) => {
          const data = heatPumpsData[location.name]
          acc[location.name] = Object.keys(data)
          return acc
        }, {})

        setHeatPumpsData(heatPumpsData)
        setLocationsData(locationsData)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [data])

  const handleModelChange = e => {
    const newModel = e.target.value
    setSelectedModel(newModel)
    setSelectedHeatPump("")
    setSelectedHeatPumpData(null)

    const selectedModelData = modelsData.find(model => model.model === newModel)
    if (selectedModelData && selectedLocation) {
      const heatPumpOptions = selectedModelData.recommendedHeatPumps.filter(
        hp => locationsData[selectedLocation].includes(hp)
      )
      if (heatPumpOptions.length > 0) {
        const defaultHeatPump = heatPumpOptions[0]
        setSelectedHeatPump(defaultHeatPump)
        setSelectedHeatPumpData(
          heatPumpsData[selectedLocation][defaultHeatPump]
        )
      }
    }
  }

  const handleLocationChange = e => {
    const newLocation = e.target.value
    setSelectedLocation(newLocation)
    setSelectedHeatPump("")
    setSelectedHeatPumpData(null)

    if (selectedModel) {
      const selectedModelData = modelsData.find(
        model => model.model === selectedModel
      )
      if (selectedModelData) {
        const heatPumpOptions = selectedModelData.recommendedHeatPumps.filter(
          hp => locationsData[newLocation].includes(hp)
        )
        if (heatPumpOptions.length > 0) {
          const defaultHeatPump = heatPumpOptions[0]
          setSelectedHeatPump(defaultHeatPump)
          setSelectedHeatPumpData(heatPumpsData[newLocation][defaultHeatPump])
        }
      }
    }
  }

  const handleHeatPumpChange = e => {
    const heatPumpSKU = e.target.value
    setSelectedHeatPump(heatPumpSKU)
    const heatPumpData = heatPumpsData[selectedLocation][heatPumpSKU]
    setSelectedHeatPumpData(heatPumpData)
  }

  const selectedModelData = modelsData.find(
    model => model.model === selectedModel
  )
  const heatPumpOptions =
    selectedModelData && selectedLocation
      ? selectedModelData.recommendedHeatPumps
          .filter(hp => locationsData[selectedLocation].includes(hp))
          .map(hp => ({
            value: hp,
            text: heatPumpsData[selectedLocation][hp].model
          }))
      : []

  const adjustCost = (cost, litres) => {
    const baseVolume = 1000
    if (!cost) return ""

    // Parsing the cost value
    const costValue = parseFloat(cost.replace("$", "").replace(/,/g, ""))

    if (isNaN(costValue) || isNaN(litres)) return ""

    // Calculating the new cost based on the volume
    const newCost = (litres / baseVolume) * costValue

    return `$${Math.round(newCost)}`
  }

  const adjusted1Year = selectedHeatPumpData
    ? adjustCost(selectedHeatPumpData["1_year"], selectedModelData?.litres)
    : "-"
  const adjusted2Year = selectedHeatPumpData
    ? adjustCost(selectedHeatPumpData["2_year"], selectedModelData?.litres)
    : "-"
  const adjusted3Year = selectedHeatPumpData
    ? adjustCost(selectedHeatPumpData["3_year"], selectedModelData?.litres)
    : "-"
  const adjusted5Year = selectedHeatPumpData
    ? adjustCost(selectedHeatPumpData["5_year"], selectedModelData?.litres)
    : "-"
  const adjusted10Year = selectedHeatPumpData
    ? adjustCost(selectedHeatPumpData["10_year"], selectedModelData?.litres)
    : "-"

  // Calculate Pay Back Time
  const calculatePayBackTime = (rrp, costPerYear) => {
    const rrpValue = parseFloat(rrp.replace("$", "").replace(/,/g, ""))
    const costValue = parseFloat(costPerYear.replace("$", "").replace(/,/g, ""))
    if (isNaN(rrpValue) || isNaN(costValue) || costValue === 0) return "-"
    const payBackTime = (rrpValue / costValue).toFixed(2)
    return payBackTime
  }

  const payBackTime = selectedHeatPumpData
    ? calculatePayBackTime(selectedHeatPumpData.rrp, adjusted1Year)
    : "-"

  return (
    <div>
      <div className={cn(style.calc)}>
        <div className={style.calc__header}>
          <h2>{"Heat Pump Calculator"}</h2>
        </div>
        <div className={style.calc__wrap}>
          <div className={style.calc__upper}>
            <div className={style.calc__selects}>
              <div>
                <Select
                  label="Select Model"
                  placeholder={{ text: "Select spa model" }}
                  name="model"
                  options={modelsData.map(model => ({
                    value: model.model,
                    text: model.model
                  }))}
                  value={selectedModel}
                  onChange={handleModelChange}
                />
              </div>
              <div>
                <Select
                  label="Select Location"
                  name="location"
                  placeholder={{ text: "Select location" }}
                  options={Object.keys(locationsData).map(region => ({
                    value: region,
                    text: region
                  }))}
                  value={selectedLocation}
                  onChange={handleLocationChange}
                />
              </div>
            </div>

            <div className={style.calc__row}>
              <Box label={"Brand"} data={selectedModelData?.brand || "-"} />
              <Box label={"Model"} data={selectedModelData?.model || "-"} />
              <Box
                label={"Litres"}
                data={selectedModelData ? selectedModelData.litres : "-"}
              />
              <Box label={"Location"} data={selectedLocation || "-"} />
            </div>
          </div>
          <div className={style.calc__lower}>
            <div className={style.calc__pump_data}>
              <h2>{"Choose Heat Pump Model"}</h2>
              <div className={style.calc__pump}>
                <Select
                  name="heatPump"
                  placeholder={{ text: "Select heat pump" }}
                  options={heatPumpOptions.map(hp => ({
                    value: hp.value,
                    text: hp.text
                  }))}
                  value={selectedHeatPump}
                  onChange={handleHeatPumpChange}
                />
              </div>
              <div className={cn(style.calc__row, style.calc__special)}>
                <Box
                  special
                  label={"Save Up To"}
                  data={adjusted10Year || "-"}
                  desc={"10 YEARS"}
                />
                <Box
                  special
                  label={"Pay Back Time"}
                  data={payBackTime || "-"}
                  desc={"YEARS"}
                />
              </div>
            </div>
            <div className={style.calc__row2}>
              <Box
                wide
                label={"Model"}
                data={selectedHeatPumpData?.model || "-"}
              />
              <Box label={"RRP"} data={selectedHeatPumpData?.rrp || "-"} />
              <Box label={"1 Year"} data={adjusted1Year || "-"} />
              <Box label={"2 Years"} data={adjusted2Year || "-"} />
              <Box label={"3 Years"} data={adjusted3Year || "-"} />
              <Box label={"5 Years"} data={adjusted5Year || "-"} />
              <Box label={"10 Years"} data={adjusted10Year || "-"} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={style.calc__disclaimer}
        dangerouslySetInnerHTML={{ __html: info }}
      />
    </div>
  )
}

export default Calculator

const Box = ({ label, data, wide, special, desc }) => {
  return (
    <div
      className={cn(style.box, {
        [style["box--wide"]]: wide,
        [style["box--special"]]: special
      })}
    >
      <div className={style.box__label}>{label}</div>
      <div className={style.box__data}>
        {data}
        {desc && <div className={style.box__desc}>{desc}</div>}
      </div>
    </div>
  )
}
