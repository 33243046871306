import React, { useContext, useEffect, useState } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import style from "./search-result.mod.scss"
import SearchContext from "@context/searchContext"
import { Index, connectHits, Configure } from "react-instantsearch-dom"
import { Button, Card, ProductCard, VideoCard, Modal } from "@components"
import LogoSvg from "@svgs/logo.svg"
import ThemeContext from "@context/themeContext"
import { filterByLocale, transformStoreItemToCard } from "@helpers/miscHelpers"
import { transformProductToCard } from "@helpers/productHelpers"
import { InstantSearch } from "react-instantsearch-dom"
import Prompt from "../riva/prompt"

const PageHit = ({
  hit,
  section,
  setFocus,
  setSearchOpen,
  searchState,
  setDefaultRefinement,
  toggleModal
}) => {
  const cardConfig =
    section === process.env.GATSBY_ALGOLIA_PRODUCT_INDEX
      ? transformProductToCard(hit)
      : section === process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX &&
        transformStoreItemToCard(hit)

  const url = hit.path || hit.item_url || "/learn/videos/"
  if (section === process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX) {
    cardConfig.link.title = "View Product"
    cardConfig.link.path = cardConfig.link.path
    // cardConfig.link.path = cardConfig.link.path + "?nview=kee-swau"
  }

  return (
    <>
      {hit.video ? (
        <>
          <VideoCard
            key={`search-video-card--${hit.objectID}`}
            heading={hit.heading}
            overlayImage={hit.overlayImage}
            onClick={() => toggleModal(hit.video)}
            elevated
            video={hit.video}
            inMenu={true}
            isLearnCard
          />
        </>
      ) : (
        <Button
          onClick={e => {
            e.preventDefault()
            setDefaultRefinement(searchState?.query)
            navigate(`${hit.url || url}`)
            setFocus(false)
            setSearchOpen(false)
          }}
          unstyled
          to={hit.url || url}
        >
          {section === process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX ? (
            <ProductCard
              key={`search-card--${hit.objectID}`}
              onSale={cardConfig.onSale}
              heading={cardConfig.heading}
              image={cardConfig.image}
              pricing={cardConfig.pricing}
              elevated
              padImage
              link={cardConfig.link}
              isSearchResult
            />
          ) : section === process.env.GATSBY_ALGOLIA_LEARN_INDEX ? (
            <>
              {!hit.heroImage && !hit.overlayImage && (
                <div className={style["search-result__learn-image"]}>
                  <LogoSvg />
                </div>
              )}
              <Card
                image={hit.heroImage || hit.overlayImage}
                heading={hit.title || hit.heading}
                smallHeading
                elevated
                links={[{ url: url }]}
                isLearnCard
                itemId={hit.id}
              />
            </>
          ) : section === process.env.GATSBY_ALGOLIA_PRODUCT_INDEX ? (
            <ProductCard
              key={`search-card--${hit.objectID}`}
              onSale={cardConfig.onSale}
              heading={cardConfig.heading}
              image={cardConfig.image}
              pricing={cardConfig.pricing}
              elevated
              padImage
              finance={cardConfig.finance}
              isSearchResult
            />
          ) : (
            //This is actually an error if this renders. Should never get here, but just in case.
            <div>{"Sorry, there are no search results."}</div>
          )}
        </Button>
      )}
    </>
  )
}

const HitsGrid = ({
  section,
  viewAll,
  categoryItems,
  searchState,
  setDefaultRefinement,
  setFocus,
  createURL,
  setSearchOpen,
  setSearchIndexName,
  index
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoInModal, setVideoInModal] = useState(null)

  const toggleIsModalOpen = boolean => setIsModalOpen(boolean)

  function toggleModal(video) {
    setVideoInModal(video)
    toggleIsModalOpen(true)
  }

  function handleSetSearchIndexName(section) {
    setSearchIndexName(section)
  }

  return (
    <>
      <div className={style["search-result__grid-category-head"]}>
        <h4 className={style["search-result__grid-category-heading"]}>
          {section === process.env.GATSBY_ALGOLIA_PRODUCT_INDEX
            ? index.products.heading
            : section === process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX
            ? index.store.heading
            : section === process.env.GATSBY_ALGOLIA_LEARN_INDEX &&
              index.learn.heading}
        </h4>
        {viewAll && categoryItems.length > 0 && (
          <div className={style["search-result__grid-category-head-view-all"]}>
            <Button
              onClick={e => {
                e.preventDefault()
                setDefaultRefinement(searchState?.query)
                handleSetSearchIndexName(section)
                navigate(
                  `/search-results/${createURL(
                    searchState
                  )}&search_type=${section}`
                )
                setSearchOpen(false)
                setFocus(false)
              }}
              unstyled
            >
              {"View All"}
            </Button>
          </div>
        )}
      </div>
      <ul>
        {categoryItems.length > 0 ? (
          categoryItems.slice(0, 3).map(hit => {
            return (
              <li key={hit.objectID}>
                <PageHit
                  section={section}
                  current
                  hit={hit}
                  setFocus={setFocus}
                  setSearchOpen={setSearchOpen}
                  setDefaultRefinement={setDefaultRefinement}
                  searchState={searchState}
                  toggleModal={toggleModal}
                />
              </li>
            )
          })
        ) : (
          <p className={style["search-result__no-results"]}>
            {"No Results Found"}
          </p>
        )}
      </ul>
      <Modal
        onChange={toggleIsModalOpen}
        globalState={isModalOpen}
        video={videoInModal}
        ariaLabel={`Open modal and play video`}
        gtm={{
          event: `video-engagement`,
          label: `Video Model Click`,
          value: videoInModal?.title
        }}
      />

      {viewAll && categoryItems.length > 0 && (
        <div className={style["search-result__view-all"]}>
          <Button
            onClick={e => {
              e.preventDefault()
              setDefaultRefinement(searchState?.query)
              handleSetSearchIndexName(section)
              navigate(
                `/search-results/${createURL(
                  searchState
                )}&search_type=${section}`
              )
              setFocus(false)
              setSearchOpen(false)
            }}
            fullWidthMobile
            secondary
            short
          >
            {"View All"}
          </Button>
        </div>
      )}
    </>
  )
}

const ResultsGrid = ({ hits, indexName, viewAll, context }) => {
  const {
    searchState,
    setDefaultRefinement,
    setSearchIndexName,
    setFocus,
    createURL,
    index
  } = context

  console.log("** HITS ** ", hits)

  const { setSearchOpen } = useContext(ThemeContext)

  const data = useStaticQuery(graphql`
    {
      products: allDatoCmsProduct(locale: "en-AU") {
        nodes {
          tags {
            id
            originalId
            name
            slug
          }
          category {
            slug
          }
          originalId
          ...ProductCard
        }
      }
      blogPosts: allDatoCmsBlogPost(locale: "en-AU") {
        nodes {
          id
          originalId
          title
          path
          leadNode {
            childMarkdownRemark {
              html
            }
          }
          publicationDate(formatString: "DD-MM-YYYY")
          excerptNode {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
          tags {
            name
            slug
            position
          }
        }
      }
      galleryItems: allDatoCmsGalleryItem(locale: "en-AU") {
        nodes {
          id
          originalId
          title
          image {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
        }
      }
      videos: allDatoCmsVideoLibraryItem(locale: "en-AU") {
        nodes {
          id
          originalId
          heading
          caption
          meta {
            createdAt
          }
          overlayImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
          video {
            provider
            providerUid
            title
            url
          }
          tags {
            name
            slug
            position
          }
        }
      }
      pages: allDatoCmsPage(locale: "en-AU") {
        nodes {
          originalId
          id
          title
          excerpt
          path
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
      locations: allDatoCmsLocation(locale: "en-AU") {
        nodes {
          id
          originalId
          path
          title
          lead
          heroImage: hero {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
      areas: allDatoCmsArea(locale: "en-AU") {
        nodes {
          id
          originalId
          path
          title
          lead
          heroImage: placeholder {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
      productsNz: allDatoCmsProduct(locale: "en-NZ") {
        nodes {
          tags {
            id
            originalId
            name
            slug
          }
          category {
            slug
          }
          originalId
          ...ProductCard
        }
      }
      blogPostsNz: allDatoCmsBlogPost(locale: "en-NZ") {
        nodes {
          id
          originalId
          title
          path
          leadNode {
            childMarkdownRemark {
              html
            }
          }
          publicationDate(formatString: "DD-MM-YYYY")
          excerptNode {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
          tags {
            name
            slug
            position
          }
        }
      }
      galleryItemsNz: allDatoCmsGalleryItem(locale: "en-NZ") {
        nodes {
          id
          originalId
          title
          image {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
        }
      }
      videosNz: allDatoCmsVideoLibraryItem(locale: "en-NZ") {
        nodes {
          id
          originalId
          heading
          caption
          meta {
            createdAt
          }
          overlayImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
          video {
            provider
            providerUid
            title
            url
          }
          tags {
            name
            slug
            position
          }
        }
      }
      pagesNz: allDatoCmsPage(locale: "en-NZ") {
        nodes {
          originalId
          id
          title
          excerpt
          path
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
      locationsNz: allDatoCmsLocation(locale: "en-NZ") {
        nodes {
          id
          originalId
          path
          title
          lead
          heroImage: hero {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
      areasNz: allDatoCmsArea(locale: "en-NZ") {
        nodes {
          id
          originalId
          path
          title
          lead
          heroImage: placeholder {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
    }
  `)

  const locale = process.env.GATSBY_LANGUAGE === "en-NZ" ? "NZ" : "AU"

  const { products, blogPosts, videos, galleryItems, pages, locations, areas } =
    locale === "NZ"
      ? {
          products: data.productsNz,
          blogPosts: data.blogPostsNz,
          videos: data.videosNz,
          galleryItems: data.galleryItemsNz,
          pages: data.pagesNz,
          locations: data.locationsNz,
          areas: data.areasNz
        }
      : data

  const localProducts = products.nodes

  console.log(localProducts)

  const localLearnArticles = pages.nodes.concat(
    blogPosts.nodes,
    locations.nodes,
    areas.nodes,
    videos.nodes,
    galleryItems.nodes
  )

  const partsAccessoriesHits = []
  const matchedProducts = []
  const matchedArticles = []

  console.log(matchedProducts)

  const localProductsSet = new Set(
    localProducts.map(product => product.netoProduct?.sku)
  )
  const localLearnArticlesSet = new Set(localLearnArticles.map(item => item.id))

  hits.forEach(hit => {
    switch (indexName) {
      case process.env.GATSBY_ALGOLIA_PRODUCT_INDEX:
        if (localProductsSet.has(hit.sku)) {
          const product = localProducts.find(
            product => product.netoProduct?.sku === hit.sku
          )
          matchedProducts.push(product)
        }
        break

      case process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX:
        partsAccessoriesHits.push({ ...hit })
        break

      case process.env.GATSBY_ALGOLIA_LEARN_INDEX:
        if (localLearnArticlesSet.has(hit.objectID)) {
          const article = localLearnArticles.find(
            item => item.id === hit.objectID
          )
          matchedArticles.push(article)
        }
        break

      default:
        break
    }
  })

  return (
    <>
      <div className={style["search-result__grid-category"]}>
        <HitsGrid
          section={indexName}
          viewAll={viewAll}
          categoryItems={[
            ...matchedProducts,
            ...matchedArticles,
            ...partsAccessoriesHits
          ]}
          searchState={searchState}
          setDefaultRefinement={setDefaultRefinement}
          setFocus={setFocus}
          setSearchIndexName={setSearchIndexName}
          createURL={createURL}
          setSearchOpen={setSearchOpen}
          index={index}
        />
      </div>
    </>
  )
}

const CustomHits = ({ hits, indexName, viewAll, context }) => {
  return (
    <>
      <ResultsGrid
        hits={hits}
        indexName={indexName}
        viewAll={viewAll}
        context={context}
      />
    </>
  )
}

const Hits = connectHits(CustomHits)

const HitsInIndex = ({ viewAll, context, siteLanguage }) => {
  return (
    <>
      <InstantSearch
        searchClient={context.searchClient}
        indexName={context.index.products.name}
        onSearchStateChange={context.onSearchStateChange}
        createURL={context.createURL}
        searchState={context.searchState}
        refresh={true}
      >
        <Index indexName={context.index.products.name}>
          <Configure hitsPerPage={18} query={context.searchState.query} />
          <Hits
            indexName={context.index.products.name}
            viewAll={viewAll}
            context={context}
          />
        </Index>
        {siteLanguage === "en-AU" && (
          <Index indexName={context.index.store.name}>
            <Configure
              hitsPerPage={18}
              query={context.searchState.query}
              filters={"user_group:1"}
            />
            <Hits
              indexName={context.index.store.name}
              viewAll={viewAll}
              context={context}
            />
          </Index>
        )}

        <Index indexName={context.index.learn.name}>
          <Configure hitsPerPage={18} query={context.searchState.query} />
          <Hits
            indexName={context.index.learn.name}
            viewAll={viewAll}
            context={context}
          />
        </Index>
      </InstantSearch>
    </>
  )
}

const SearchResult = ({
  index,
  viewAll = true,
  context = {},
  contained = false,
  modifiers = []
}) => {
  const [hasResults, setHasResults] = useState(true)
  const siteLanguage = process.env.GATSBY_LANGUAGE
  contained && modifiers.push(style["search-result--contained"])

  return (
    <>
      <div className={[[style["search-result"], ...modifiers].join(" ")]}>
        <div className={style["search-result__wrap"]}>
          <Prompt />
          {hasResults ? (
            <>
              <div
                className={
                  siteLanguage === "en-AU"
                    ? style["search-result__grid"]
                    : style["search-result__grid-nostore"]
                }
              >
                <HitsInIndex
                  index={index}
                  key={index.name}
                  viewAll={viewAll}
                  context={context}
                  setHasResults={setHasResults}
                  siteLanguage={siteLanguage}
                />
              </div>
            </>
          ) : (
            <p className={style["search-result__no-results"]}>
              {"Sorry, no search results were found. Please try again."}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchResult
