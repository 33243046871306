import React, { useEffect, useState } from "react"
import style from "./infinite-results.mod.scss"
import { useStaticQuery, graphql } from "gatsby"
import { Block, Button } from "@components"
import { connectInfiniteHits } from "react-instantsearch-dom"
import ProductCard from "../../elements/product-card"
import Grid from "../grid"
import StateResults from "./state-results"
import {
  filterByLocale,
  transformStoreItemToCard
} from "../../../helpers/miscHelpers"
import LogoSvg from "@svgs/logo.svg"
import { transformProductToCard } from "@helpers/productHelpers"
import { Card, Modal, VideoCard } from "@components"

const InfiniteHits = ({
  hits,
  refineNext,
  hasMore,
  query,
  searchIndexName
}) => {
  const data = useStaticQuery(graphql`
    {
      products: allDatoCmsProduct(locale: "en-AU") {
        nodes {
          tags {
            id
            originalId
            name
            slug
          }
          category {
            slug
          }
          originalId
          ...ProductCard
        }
      }
      blogPosts: allDatoCmsBlogPost(locale: "en-AU") {
        nodes {
          id
          originalId
          title
          path
          leadNode {
            childMarkdownRemark {
              html
            }
          }
          publicationDate(formatString: "DD-MM-YYYY")
          excerptNode {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
          tags {
            name
            slug
            position
          }
        }
      }
      galleryItems: allDatoCmsGalleryItem(locale: "en-AU") {
        nodes {
          id
          originalId
          title
          image {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
        }
      }
      videos: allDatoCmsVideoLibraryItem(locale: "en-AU") {
        nodes {
          id
          originalId
          heading
          caption
          meta {
            createdAt
          }
          overlayImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
          video {
            provider
            providerUid
            title
            url
          }
          tags {
            name
            slug
            position
          }
        }
      }
      pages: allDatoCmsPage(locale: "en-AU") {
        nodes {
          originalId
          id
          title
          excerpt
          path
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
      productsNz: allDatoCmsProduct(locale: "en-NZ") {
        nodes {
          tags {
            id
            originalId
            name
            slug
          }
          category {
            slug
          }
          originalId
          ...ProductCard
        }
      }
      blogPostsNz: allDatoCmsBlogPost(locale: "en-NZ") {
        nodes {
          id
          originalId
          title
          path
          leadNode {
            childMarkdownRemark {
              html
            }
          }
          publicationDate(formatString: "DD-MM-YYYY")
          excerptNode {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
          tags {
            name
            slug
            position
          }
        }
      }
      galleryItemsNz: allDatoCmsGalleryItem(locale: "en-NZ") {
        nodes {
          id
          originalId
          title
          image {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
        }
      }
      videosNz: allDatoCmsVideoLibraryItem(locale: "en-NZ") {
        nodes {
          id
          originalId
          heading
          caption
          meta {
            createdAt
          }
          overlayImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "160"
                fit: "crop"
                w: "300"
                q: 60
                auto: "format"
              }
            )
          }
          video {
            provider
            providerUid
            title
            url
          }
          tags {
            name
            slug
            position
          }
        }
      }
      pagesNz: allDatoCmsPage(locale: "en-NZ") {
        nodes {
          originalId
          id
          title
          excerpt
          path
          heroImage {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "160", fit: "crop", w: "300", auto: "format" }
            )
          }
        }
      }
    }
  `)

  const locale = process.env.GATSBY_LANGUAGE === "en-NZ" ? "NZ" : "AU"

  const { products, blogPosts, videos, galleryItems, pages } =
    locale === "NZ"
      ? {
          products: data.productsNz,
          blogPosts: data.blogPostsNz,
          videos: data.videosNz,
          galleryItems: data.galleryItemsNz,
          pages: data.pagesNz
        }
      : data

  const localProducts = products.nodes

  const localLearnArticles = pages.nodes.concat(
    blogPosts.nodes,
    videos.nodes,
    galleryItems.nodes
  )

  const partsAccessoriesHits = []
  const matchedProducts = []
  const matchedArticles = []

  const localProductsSet = new Set(
    localProducts.map(product => product.netoProduct?.sku)
  )
  const localLearnArticlesSet = new Set(localLearnArticles.map(item => item.id))

  hits.forEach(hit => {
    switch (searchIndexName) {
      case process.env.GATSBY_ALGOLIA_PRODUCT_INDEX:
        if (localProductsSet.has(hit.sku)) {
          if (!hit.sku) {
            return
          }
          const product = localProducts.find(
            product => product.netoProduct?.sku === hit.sku
          )
          matchedProducts.push(product)
        }
        break

      case process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX:
        partsAccessoriesHits.push({ ...hit })
        break

      case process.env.GATSBY_ALGOLIA_LEARN_INDEX:
        if (localLearnArticlesSet.has(hit.objectID)) {
          const article = localLearnArticles.find(
            item => item.id === hit.objectID
          )
          matchedArticles.push(article)
        }
        break

      default:
        break
    }
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoInModal, setVideoInModal] = useState(null)

  const toggleIsModalOpen = boolean => setIsModalOpen(boolean)

  function toggleModal(video) {
    setVideoInModal(video)
    toggleIsModalOpen(true)
  }

  const regex = new RegExp(/^(?!\s*$).+/)
  const hasResults = regex.test(query) && (matchedArticles || matchedProducts)
  return (
    <div className={style["infinite-results"]}>
      <div className={style["infinite-results__found"]}>
        <StateResults hasResults={hasResults} />
      </div>
      <div className={style["infinite-results__hits"]}>
        {searchIndexName === process.env.GATSBY_ALGOLIA_LEARN_INDEX ? (
          <Grid moduleStyle={"2-up-grid"}>
            {matchedArticles.map(result => {
              const url = result.path || result.item_url || "/learn/videos/"
              return (
                <div className={style["infinite-results__card-content"]}>
                  {result.video ? (
                    <>
                      <VideoCard
                        key={`search-video-card--${result.objectID}`}
                        caption={result.caption}
                        heading={result.heading}
                        overlayImage={result.overlayImage}
                        onClick={() => toggleModal(result.video)}
                        elevated
                        video={result.video}
                      />
                    </>
                  ) : (
                    <>
                      {!result.heroImage && !result.overlayImage && (
                        <div className={style["infinite-results__learn-image"]}>
                          <LogoSvg />
                        </div>
                      )}
                      <Card
                        subHeading={result.excerpt || result.caption}
                        image={result.heroImage || result.overlayImage}
                        heading={result.title || result.heading}
                        elevated
                        links={[{ url: url, title: "Read More" }]}
                      />
                    </>
                  )}
                </div>
              )
            })}
            <Modal
              onChange={toggleIsModalOpen}
              globalState={isModalOpen}
              video={videoInModal}
              ariaLabel={`Open modal and play video`}
              gtm={{
                event: `video-engagement`,
                label: `Video Model Click`,
                value: videoInModal?.title
              }}
            />
          </Grid>
        ) : searchIndexName === process.env.GATSBY_ALGOLIA_SPA_STORE_INDEX ? (
          <Grid moduleStyle={"3-up-grid"}>
            {partsAccessoriesHits.map(hit => {
              if (!hit.price_groups) {
                return null
              }
              const cardConfig = transformStoreItemToCard(hit)
              cardConfig.link.path = cardConfig.link.path
              return (
                <ProductCard
                  key={`search-card--${cardConfig.id}`}
                  onSale={cardConfig.onSale}
                  heading={cardConfig.heading}
                  image={cardConfig.image}
                  pricing={cardConfig.pricing}
                  elevated
                  padImage
                  link={cardConfig.link}
                  isSearchResult
                />
              )
            })}
          </Grid>
        ) : (
          searchIndexName === process.env.GATSBY_ALGOLIA_PRODUCT_INDEX && (
            <Grid moduleStyle={"3-up-grid"}>
              {matchedProducts.map(product => {
                const productCardConfig = transformProductToCard(product)
                return <ProductCard {...productCardConfig} />
              })}
            </Grid>
          )
        )}
      </div>
      {hasMore && (
        <div className={style["infinite-results__load-more"]}>
          <Button
            type="button"
            onClick={refineNext}
            disabled={!hasMore}
            children="Load More"
            fullWidthMobile
            block
            short
          />
        </div>
      )}
    </div>
  )
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

const InfiniteResults = ({ context }) => {
  return (
    <Block>
      <CustomInfiniteHits
        query={context.defaultRefinement}
        searchIndexName={context.searchIndexName}
      />
    </Block>
  )
}
export default InfiniteResults
